import React from "react";

const TextPath = ({ textPath }) => {
    return (
        <text fill={textPath.textColor}>
            <textPath
                fontFamily={textPath.fontFamily}
                fontSize={textPath.fontSize}
                textAnchor={textPath.textAnchor}
                startOffset={textPath.startOffset}
                href={"#" + textPath.pathId}
            >
                {textPath.str}
            </textPath>
        </text>
    );
};

export default TextPath;
