import React, { useState } from "react";
import { useAppContext } from "../../context/contextLib";
import { Form, Input, Button, Checkbox, Typography, Card, Drawer } from "antd";
import styled from 'styled-components';

//import globalStaticStyles from "./SVGStaticResources/globalStaticStyles.js";
import katexStaticStyles from "./SVGStaticResources/katexStaticStyles.js";
import katexStaticBase64Fonts from "./SVGStaticResources/katexStaticBase64Fonts.js";
import highestPriorityStaticStyles from "./SVGStaticResources/highestPriorityStaticStyles.js";


/* Share button y panel para compartir Snapshot de diagrama actual */

const StyledEmbedArea = styled.textarea`
	width: 100%;
	border: solid 1px;
	padding: 0.5rem;
`

export default ({type="secondary"}) => {
	const { uploadSvgSnapshot, katexMapNotEmpty, markdownMapNotEmpty} = useAppContext();
	const [showDetails, setShowDetails] = useState(false);
	const [snapshotUrl, setSnapshotUrl] = useState(null);
	const [svgWidth, setSvgWidth] = useState(400)
	const [svgHeight, setSvgHeight] = useState(400);

	//const globalInject = globalStaticStyles;
	const globalInject = "";
    const katexInject = katexStaticStyles;
    const katexBase64Fonts = katexStaticBase64Fonts;

	const onCreateSnapshot = async () => {

		//TODO: loading indicators
		console.log("onCreateSnapshot");
		debugger;
		const svg = document.querySelector('#mainsvg').cloneNode(true);

		if (katexMapNotEmpty && markdownMapNotEmpty){
        	svg.children[0].innerHTML = svg.children[0].innerHTML + globalInject +  katexInject + highestPriorityStaticStyles  + katexBase64Fonts;
	    }else if(katexMapNotEmpty && !markdownMapNotEmpty){
	        svg.children[0].innerHTML = svg.children[0].innerHTML + globalInject +  katexInject + highestPriorityStaticStyles  + katexBase64Fonts;
	    }else if(!katexMapNotEmpty && markdownMapNotEmpty){
	        svg.children[0].innerHTML = svg.children[0].innerHTML + globalInject  + highestPriorityStaticStyles;
	    }else if(!katexMapNotEmpty && !markdownMapNotEmpty){
	        svg.children[0].innerHTML = svg.children[0].innerHTML + globalInject  + highestPriorityStaticStyles;
	    }

		const bbox = svg.getBoundingClientRect();
		const data = new XMLSerializer().serializeToString(svg);
		const url = await uploadSvgSnapshot(data);
		if (url) {
			setSnapshotUrl(url);
			setShowDetails(true);
			setSvgWidth(bbox.width)
			setSvgHeight(bbox.height);
		}
	};

	const onClose = () => {
		setShowDetails(false);
	};

	const iframeCode = `<iframe src="${snapshotUrl}"  width="${Math.round(svgWidth)}" height="${Math.round(svgHeight)}"></iframe>`

	return (
		<>
			<Button onClick={onCreateSnapshot} type={type}>
				Share diagram snapshot
			</Button>
			<Drawer
				width="50%"
				title="Share Options"
				placement="right"
				closable={false}
				onClose={onClose}
				visible={showDetails}
			>
				<Card title="Svg Image Url">
					<Input value={snapshotUrl} readonly />
				</Card>

				<Card title="Embed in Website">
					<StyledEmbedArea reaOnly value={iframeCode}>
					</StyledEmbedArea>
				</Card>
			</Drawer>
		</>
	);
};
