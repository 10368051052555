import React from 'react'
import {
    Drawer,
} from "antd";

export default ({diagramType, visible, onClose}) => {
    return (<Drawer
                    width="480px"
                    title={`Quick reference`}
                    placement="right"
                    closable={true}
                    onClose={onClose}
                    visible={visible}
                    bodyStyle={{ padding: 0 }}
                >
                    <iframe
                        frame="none"
                        style={{ border: "none" }}
                        src={`https://diagram-codes-cloud-docs.netlify.app/#/diagrams/${diagramType}/README`}
                        width="100%"
                        height="100%"
                    />
                </Drawer>)
}
