export const getProjectWithoutDeletedDocuments = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      groupID
      teamID
      documents(filter: {deleted: {ne: true}}) {
        items {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;