import React, { useEffect } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Input } from "antd";
import { useAppContext } from "../../context/contextLib";
import ExplorerDocumentItem from "./explorerDocumentItem";
import ExplorerDocumentItemRow from "./explorerDocumentItemRow";



export default ({ documents, isDraft = false }) => {
	return (
		<List
			dataSource={documents}
			renderItem={(item) => (
				<ExplorerDocumentItemRow item={item} isDraft={isDraft} />
			)}
		/>
	);
};
