import React, { useState, useEffect, useContext } from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/home";
import Signup from "./auth/signup";
import AccountReady from "./pages/accountReady";
import ConfirmSignup from "./auth/confirmSignup";
import CreateAccount from "./pages/createAccount";
import Login from "./auth/login";
import ForgotPasswordPage from "./auth/forgot";
import { v4 as uuid } from "uuid";

import { AppContext } from "./context/contextLib";
import { Auth, Storage } from "aws-amplify";
import StateKeys from "./StateKeys";
import AppContainer from "./layout/appContainer";
import LoadingFullScreen from "./pages/loadingFullScreen.js";
import { message } from "antd";

import fastDiff from "fast-diff";
import dayjs from "dayjs";

import { API, graphqlOperation } from "aws-amplify";
import {
  listProjects,
  getTeamDetails,
  listTeamDetailss,
  getTeamMembers,
  //searchProjects as querySearchProjects,
  getProject as queryGetProject,
  docsByProject as queryDocsByProject,
  getDocument as queryGetDocument,
  getDraft as queryGetDraft,
  recentDraftsByUser as queryListDraftsByUser,
} from "./graphql/queries";

import queryGetRecentDocs from "./graphql/custom/recentDocumentsByTeam";
import { getProjectWithoutDeletedDocuments } from "./graphql/custom/getProjectWithoutDeletedDocuments";

import {
  createProject,
  addTeamMember as addTeamMemberMutation,
  createDraft as createDraftMutation,
  updateDraft as updateDraftMutation,
  createDocument as createDocumentMutation,
  updateDocument as updateDocumentMutation,
  updateProject as updateProjectMutation,
  createRecentDocument as createRecentDocumentMutation,
  createStripePortalSession as createStripePortalSessionMutation,
} from "./graphql/mutations";

import { onUpdateDocumentWithId } from "./graphql/subscriptions";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";



const Automerge = require("automerge");
const AWS_DATETIME_FORMAT = "YYYY-MM-DDThh:mm:ss.sssZ";

/* El CLIENTID lo usamos para saber si una subscripción viene de otro cliente o es el mismo dispositivo */
const CLIENTID = uuid();

export default function App() {
  const history = useHistory();

  /* El initialLocationPath sirve para guardar el path si el usuario no esta
     autenticado. Al autenticar se redirige a este path */
  const [initialLocationPath, setInitialLocationPath] = useState("/");
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  //Datos requeridos para pago

  const [selectedPriceId, setSelectedPriceId] = useState(null);
  if (!process.env.REACT_APP_PRICE_INFO) {
    throw new Error("Env var REACT_APP_PRICE_INFO not set");
  }
  const [priceList, setPriceList] = useState(
    JSON.parse(process.env.REACT_APP_PRICE_INFO)
  );

  const [initializingSession, setInitializingSession] = useState(false);
  //Is admin del current team?
  const [isAdmin, setIsAdmin] = useState(false);
  const [temporalUserEmail, setTemporalUserEmail] = useState(null);
  //Si el usuario hace signup guardamos aquí el pwd para poder loguear al usuario
  //automaticamente
  const [temporalUserPassword, setTemporalUserPassword] = useState("");
  //User teams
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [currentTeamMembers, setCurrentTeamMembers] = useState([]);
  //User projects
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState();

  //Document explorer
  const [recentFiles, setRecentFiles] = useState([]);
  const [documentSearchResults, setDocumentSearchResults] = useState([]);
  const [projectSearchResults, setProjectSearchResults] = useState([]);

  const [currentProjectDocuments, setCurrentProjectDocuments] = useState([]);
  const [currentDocument, setCurrentDocument] = useState();

  const [currentDraft, setCurrentDraft] = useState();

  //GOTOLINE AceEditor EVENTS
  const [lineNumber, setLineNumber] = useState([]);

  //selected theme
  const [selectedTheme, setSelectedTheme] = useState(null);

  //Private drafts:
  const [myDrafts, setMyDrafts] = useState([]);

  //flags que guardan si un diagrama tiene o no latex/markdown expression, usado para exportar svg
  const [katexMapNotEmpty, setKatexMapNotEmpty] = useState(null);
  const [markdownMapNotEmpty, setMarkdownMapNotEmpty] = useState(null);

  //Subscripción a documento actual.
  //Guardamos el id del documento al que estamos suscritos
  const [documentSubscriptionId, setDocumentSubscriptionId] = useState();
  let subscription;

  //On load: check current user session
  useEffect(() => {
    onLoad();
  }, []);

  /* Efectos a ejecutar cuando cambia el estado de autenticacion */
  useEffect(() => {
    if (isUserAuthenticated) {
      loadTeams();
      loadProjects();
    } else {
      setRecentFiles([]);
      setCurrentTeam(null);
      setTeams([]);
      setProjects([]);
      setMyDrafts([]);

      setCurrentDocument(null);
      setCurrentDraft(null);
    }
  }, [isUserAuthenticated]);

  //Efecto para katexMapNotEmpty
  useEffect(() => {
    setKatexMapNotEmpty(katexMapNotEmpty);
  }, [katexMapNotEmpty]);

  //Efecto para markdownMapNotEmpty
  useEffect(() => {
    setMarkdownMapNotEmpty(markdownMapNotEmpty);
  }, [markdownMapNotEmpty]);


  //Efecto para ejecutar cuando se cambia el line number
  useEffect(() => {
    setLineNumber(lineNumber);
  }, [lineNumber]);

  //Efecto para ejecutar cuando se cambia el selected theme
  useEffect(() => {
    setSelectedTheme(selectedTheme);
  }, [selectedTheme]);

  //Set default Team
  useEffect(() => {
    setCurrentTeam(null);
    if (teams.length > 0) {
      setCurrentTeam(teams[0]);
    }
  }, [teams]);

  useEffect(() => {
    setCurrentTeamMembers([]);
    setRecentFiles([]);
    setIsAdmin(false);

    if (currentTeam && isUserAuthenticated) {
      loadCurrentTeamMembers();
      loadRecentDocuments();
    }
  }, [currentTeam]);

  //Validar si usuario actual es admin del currentTeam
  useEffect(() => {
    console.log(
      "effect cambio currentTeamMembers: currentUserId",
      currentUserId
    );
    setIsAdmin(false);
    const currentUserMember = currentTeamMembers.find(
      (u) => u.userID === currentUserId
    );
    if (currentUserMember) {
      setIsAdmin(currentUserMember.isAdmin);
    }
  }, [currentTeamMembers, currentUserId]);

  //Efectos cuando cambia el current project
  useEffect(() => {
    setCurrentDocument(null);
    if (currentProject) {
      setCurrentProjectDocuments(currentProject.documents.items);
    } else {
      setCurrentProjectDocuments([]);
    }
  }, [currentProject]);

  //Efectos al actualizar el current document
  useEffect(() => {
    //Necesitamos cancelar la subscripción activa y establecer otra?
    if (currentDocument && documentSubscriptionId != currentDocument.id) {
      //Si la subscripcion existe cancelarla
      if (subscription) {
        subscription.unsubscribe();
      }

      //Establecer subscripción a documento con dicho id
      (async () => {
        const opts = {
          id: currentDocument.id,
        };

        console.log("Subscription opts:", opts);
        subscription = API.graphql(
          graphqlOperation(onUpdateDocumentWithId, opts)
        ).subscribe({
          next: (data) => {
            const doc = data.value.data.onUpdateDocumentWithId;
            /* Verificar si el clientId es diferente, en ese caso los cambios vienen de otro cliente 
               y debemos hacer el merge con el doc actual */
          },
          error: (error) => {
            console.warn(error);
          },
        });
      })();
      setDocumentSubscriptionId(currentDocument.id);
    }
  }, [currentDocument]);

  const clearState = () => {
    setCurrentDocument(null);
    setCurrentTeam(null);
    setProjectSearchResults([]);
    setCurrentProjectDocuments([]);
    setCurrentUserId(null);
    setCurrentDraft(null);
    setCurrentTeamMembers([]);
    setDocumentSearchResults([]);
    setLineNumber([]);
    setSelectedTheme([]);
    setKatexMapNotEmpty(null);
    setMarkdownMapNotEmpty(null);
  };

  const logout = async () => {
    //Si vuelve a entrar resetear el path original.
    setIsUserAuthenticated(false);
    clearState();
    setInitialLocationPath("/");
    setIsUserAuthenticated(false);
    await Auth.signOut();
  };

  const uploadSvgSnapshot = async (svgSnapshot) => {
    /* Subir SVG a bucket publico */
    try {
      const fileName = "snapshot-" + uuid() + ".svg";
      const resultStorage = await Storage.put(fileName, svgSnapshot, {
        contentType: "image/svg+xml",
      });

      console.log("resultStorage:", resultStorage);
      const url = await Storage.get(fileName);
      //Remover parametros de la URL firmada
      return url.substring(0, url.indexOf("?"));
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const loadCurrentTeamMembers = async () => {
    if (!isUserAuthenticated) return;

    try {
      const resultData = await API.graphql(
        graphqlOperation(getTeamMembers, { teamId: currentTeam.id })
      );
      console.log("getTeamMembers results", resultData);
      setCurrentTeamMembers(resultData.data.getTeamMembers);
    } catch (e) {
      //TODO: handle
      console.log(e);
      alert(e.toString());
    }
  };

  const loadRecentDocuments = async () => {
    try {
      const opts = {
        limit: 40,
        teamID: currentTeam.id,
        sortDirection: "DESC",
      };

      console.log("loadRecentDocuments opts:", opts);
      const resultData = await API.graphql(
        graphqlOperation(queryGetRecentDocs, opts)
      );
      console.log("queryGetRecentDocs results", resultData);
      const recent = resultData.data.recentDocumentsByTeam.items.map(
        (m) => m.document
      );
      const ids = [];
      const uniqDocs = recent.filter((elem) => {
        if (ids.indexOf(elem.id) >= 0) return false;
        if (elem.deleted == true) return false;
        ids.push(elem.id);
        return true;
      });

      setRecentFiles(uniqDocs);
    } catch (e) {
      //TODO: handle
      console.log(e);
      alert(e.toString());
    }
  };

  const loadTeams = async () => {
    if (!isUserAuthenticated) return;

    try {
      const resultData = await API.graphql(graphqlOperation(listTeamDetailss));
      console.log("loadTeams result", resultData);
      setTeams(resultData.data.listTeamDetailss.items);
    } catch (e) {
      //TODO: handle
      console.log(e);
      alert(e.toString());
    }
  };

  const loadProjects = async () => {
    try {
      const opts = {
        limit: 1000,
      };
      const resultData = await API.graphql(
        graphqlOperation(listProjects, opts)
      );
      console.log(resultData);
      setProjects(resultData.data.listProjects.items);
    } catch (e) {
      //TODO: handle
      console.log(e);
      alert(e.toString());
    }
  };

  const searchProjects = async(term) => {
    //do nothing for now
  }

  // const searchProjects = async (term) => {
  //   try {
  //     const opts = {
  //       filter: {
  //         name: {
  //           matchPhrasePrefix: term,
  //         },
  //       },
  //     };
  //     console.log("search opts", opts);
  //     setProjectSearchResults([]);
  //     if (term.trim().length === 0) {
  //       return;
  //     }
  //     const resultData = await API.graphql(
  //       graphqlOperation(querySearchProjects, opts)
  //     );
  //     console.log(resultData);
  //     setProjectSearchResults(resultData.data.searchProjects.items);
  //   } catch (e) {
  //     //TODO: handle
  //     console.log(e);
  //     alert(e.toString());
  //   }
  // };

  const loadProjectDetails = async (id) => {
    //TODO: mirar si el proyecto ya está en cache
    //TODO: loading
    setCurrentProject(null);
    try {
      const opts = {
        id: id,
      };

      const resultData = await API.graphql(
        graphqlOperation(getProjectWithoutDeletedDocuments, opts)
      );
      console.log(resultData);
      const project = resultData.data.getProject;
      setCurrentProject(project);
      //Cargar lista de documentos del proyecto
      //TODO: Manejar paginación! guardar nextToken
      //setCurrentProjectDocuments(project.documents.items);
    } catch (e) {
      //TODO: handle
      console.log(e);
      alert(e.toString());
    }
  };

  const loadCurrentProjectDocuments = async (projectId) => {
    setCurrentProjectDocuments([]);
    try {
      const opts = {
        projectID: projectId,
        updatedAt: {
          lt: dayjs()
            .add(1, "day")
            .format(AWS_DATETIME_FORMAT),
        },
        sortDirection: "DESC",
        limit: 1000,
        filter: { deleted: { ne: true } },
      };

      const resultData = await API.graphql(
        graphqlOperation(queryDocsByProject, opts)
      );
      console.log("loadCurrentProjectDocuments resultData:", resultData);
      setCurrentProjectDocuments(resultData.data.docsByProject.items);
    } catch (e) {
      //TODO: handle
      console.log(e);
      alert(e.toString());
    }
  };

  const loadMyDrafts = async () => {
    //TODO: Paginación!
    try {
      const opts = {
        userID: currentUserId,
        updatedAt: {
          lt: dayjs()
            .add(1, "day")
            .format(AWS_DATETIME_FORMAT),
        },
        sortDirection: "DESC",
        limit: 1000,
        filter: { deleted: { ne: true } },
      };

      console.log("loadMyDrafts opts:", opts);

      const resultData = await API.graphql(
        graphqlOperation(queryListDraftsByUser, opts)
      );
      console.log(resultData);
      setMyDrafts(resultData.data.recentDraftsByUser.items);
    } catch (e) {
      //TODO: handle
      console.log(e);
      alert(e.toString());
    }
  };

  const cleanProjectSearchResults = () => {
    setProjectSearchResults([]);
  };

  const addProject = async (projectItem) => {
    const opts = { input: projectItem };
    const result = await API.graphql(graphqlOperation(createProject, opts));
    //Add to local state
    setProjects([...projects, result.data.createProject]);
    return result.data.createProject;
  };

  const updateProject = async (projectItem) => {
    console.log("projecTItem:", projectItem);
    try {
      const mutationParameters = {
        id: projectItem.id,
        name: projectItem.name,
        groupID: projectItem.groupID,
        teamID: projectItem.teamID,
      };

      const resultData = await API.graphql(
        graphqlOperation(updateProjectMutation, { input: mutationParameters })
      );
      loadProjects();
    } catch (e) {
      //TODO: handle
      console.log(e);

      alert(e.toString());
    }
  };

  const createStripeBillingPortalSession = async () => {
    console.log("createStripeBillingPortalSession:");
    try {
      const mutationParameters = {
        customerId: currentTeam.account.stripeCustomer,
        teamId: currentTeam.id,
        returnUrl: window.location.href,
      };

      console.log(mutationParameters);

      const resultData = await API.graphql(
        graphqlOperation(createStripePortalSessionMutation, mutationParameters)
      );
      console.log(resultData);
      return resultData.data.createStripePortalSession.sessionUrl;
    } catch (e) {
      //TODO: handle
      console.log(e);
      alert(e.toString());
      return null;
    }
  };

  const addDocument = async (documentItem) => {
    try {
      const opts = {
        input: { ...documentItem, clientId: CLIENTID, userID: currentUserId },
      };
      const resultData = await API.graphql(
        graphqlOperation(createDocumentMutation, opts)
      );
      const doc = resultData.data.createDocument;
      setCurrentDocument(doc);
      return doc;
    } catch (e) {
      //TODO: handle
      console.log(e);

      alert(e.toString());
    }
  };

  const addDraft = async (documentItem) => {
    try {
      const opts = {
        input: { ...documentItem, clientId: CLIENTID, userID: currentUserId },
      };
      const resultData = await API.graphql(
        graphqlOperation(createDraftMutation, opts)
      );
      const doc = resultData.data.createDraft;
      setCurrentDraft(doc);
      return doc;
    } catch (e) {
      //TODO: handle
      console.log(e);

      alert(e.toString());
    }
  };

  /* Agregar un nuevo documento a la lista de recientes.
     Sólo se agrega si no está en la lista*/
  const addRecentDocument = async (recentDocItem) => {
    try {
      const found = recentFiles.find((e) => e.id === recentDocItem.id);
      if (found) return null;

      const opts = { input: recentDocItem };
      const resultData = await API.graphql(
        graphqlOperation(createRecentDocumentMutation, opts)
      );
      const doc = resultData.data.createRecentDocument;
      console.log("recent doc:", doc);
      return doc;
    } catch (e) {
      //TODO: handle
      console.log(e);

      alert(e.toString());
    }
  };

  const loadDocument = async (id) => {
    try {
      const opts = { id: id };
      const resultData = await API.graphql(
        graphqlOperation(queryGetDocument, opts)
      );

      const newDoc = resultData.data.getDocument;
      setCurrentDocument(newDoc);

      return newDoc;
    } catch (e) {
      //TODO: handle
      console.log(e);

      alert(e.toString());
    }
  };

  const loadDraft = async (id) => {
    try {
      const opts = { id: id };
      const resultData = await API.graphql(
        graphqlOperation(queryGetDraft, opts)
      );

      const newDoc = resultData.data.getDraft;
      setCurrentDraft(newDoc);

      return newDoc;
    } catch (e) {
      //TODO: handle
      console.log(e);

      alert(e.toString());
    }
  };

  const updateDocument = async (documentItem) => {
    const mutationParameters = {
      id: documentItem.id,
      clientId: CLIENTID,
      userID: documentItem.userID,
      diagramCode: documentItem.diagramCode,
      title: documentItem.title,
      theme: documentItem.theme,
      projectID: documentItem.projectID,
      groupID: documentItem.groupID,
      deleted: documentItem.deleted,
    };

    try {
      const opts = { input: mutationParameters };
      const resultData = await API.graphql(
        graphqlOperation(updateDocumentMutation, opts)
      );

      const doc = resultData.data.updateDocument;
      //No actualizamos el doc actual porque se perderían los
      //cambios hechos durante el save

      //Actualizar archivo en recent files si está presente
      const recentDoc = recentFiles.find((e) => e.id === documentItem.id);
      console.log("recentDoc:", recentDoc);
      if (recentDoc) {
        const ix = recentFiles.indexOf(recentDoc);
        //reemplazar elemento
        recentFiles.splice(ix, 1, doc);
        console.log("newRecents:", recentFiles);
        setRecentFiles(recentFiles);
      }
      return true;
    } catch (e) {
      //TODO: handle
      console.log(e);

      alert(e.toString());
    }
  };

  const deleteDocument = async (document) => {
    const deletedDoc = { ...document, deleted: true };
    const success = await updateDocument(deletedDoc);
    //Quitarlo de lista de docs del proyecto y de los recents
    if (success) {
      setCurrentProjectDocuments(
        currentProjectDocuments.filter((d) => d.id != document.id)
      );
      setRecentFiles(recentFiles.filter((r) => r.id != document.id));
    }
    return success;
  };

  const updateDraft = async (draftItem) => {
    const mutationParameters = {
      id: draftItem.id,
      clientId: CLIENTID,
      userID: draftItem.userID,
      diagramCode: draftItem.diagramCode,
      title: draftItem.title,
      theme: draftItem.theme,
      deleted: draftItem.deleted,
    };

    try {
      const opts = { input: mutationParameters };
      const resultData = await API.graphql(
        graphqlOperation(updateDraftMutation, opts)
      );

      const doc = resultData.data.updateDraft;
      //No actualizamos el doc actual porque se perderían los
      //cambios hechos durante el save
      return true;
    } catch (e) {
      //TODO: handle
      console.log(e);

      alert(e.toString());
    }
  };

  const deleteDraft = async (draftItem) => {
    const deletedDraft = { ...draftItem, deleted: true };
    const success = await updateDraft(deletedDraft);
    //Quitarlo de lista de drafts
    if (success) {
      setMyDrafts(myDrafts.filter((d) => d.id != draftItem.id));
    }
    return success;
  };

  /* TODO: Validar que solo el admin del grupo pueda agregar miembros*/
  const addTeamMember = async ({ email, teamId }) => {
    try {
      const opts = {
        email,
        teamId,
      };
      const resultData = await API.graphql(
        graphqlOperation(addTeamMemberMutation, opts)
      );
      console.log(resultData);
      loadCurrentTeamMembers();
      return resultData;
    } catch (e) {
      if (e.errors && e.errors.length > 0) {
        if ((e.errors[0].message = "USER_ALREADY_EXISTS")) {
          message.error(
            "The user already belongs to a team, please use another email",
            2.5
          );
        }
      }
    }
  };

  /* Cuando se carga la app revisamos si el usuario esta autenticado.
     En caso de estarlo se deja que siga al path.
     De lo contrario se muestra el login pero se guarda e path inicial
     para poderlo redirigir cuando la autenticacion sea exitosa */

  async function onLoad() {
    try {
      //Important: Clean all state here
      setSelectedTheme([]);
      setLineNumber([]);
      setProjects([]);
      setTeams([]);
      setCurrentDocument(null);
      setCurrentDraft(null);
      setProjectSearchResults([]);
      setDocumentSearchResults([]);
      setInitializingSession(true);
      setMarkdownMapNotEmpty(null);
      setKatexMapNotEmpty(null);
      const session = await Auth.currentSession();
      console.log("session:", session);
      setCurrentUserId(session.idToken.payload["cognito:username"]);
      setIsUserAuthenticated(true);
    } catch (e) {
      console.log(e);
      setIsUserAuthenticated(false);
      setCurrentUserId(null);
    } finally {
      setInitializingSession(false);
    }
  }

  if (initializingSession) {
    return <LoadingFullScreen />;
  }

  return (
    <AppContext.Provider
      value={{
        CLIENTID,
        createStripeBillingPortalSession,
        priceList,
        selectedPriceId,
        initializingSession,
        setSelectedPriceId,
        isUserAuthenticated,
        initialLocationPath,
        setInitialLocationPath,
        setCurrentUserId,
        setIsUserAuthenticated,
        temporalUserEmail,
        setTemporalUserEmail,
        logout,
        clearState,
        teams,
        currentTeam,
        isAdmin,
        currentTeamMembers,
        loadCurrentTeamMembers,
        addTeamMember,
        loadTeams,
        projects,
        loadProjects,
        searchProjects,
        addProject,
        updateProject,
        setCurrentProject,
        temporalUserPassword,
        setTemporalUserPassword,
        recentFiles,
        documentSearchResults,
        projectSearchResults,
        cleanProjectSearchResults,
        loadProjectDetails,
        currentProject,
        currentProjectDocuments,
        loadCurrentProjectDocuments,
        addDocument,
        currentDocument,
        setCurrentDocument,
        loadDocument,
        updateDocument,
        deleteDocument,
        addRecentDocument,
        loadRecentDocuments,
        setCurrentDraft,
        loadMyDrafts,
        myDrafts,
        addDraft,
        updateDraft,
        deleteDraft,
        currentDraft,
        loadDraft,
        uploadSvgSnapshot,
        lineNumber,
        setLineNumber,
        selectedTheme,
        setSelectedTheme,
        katexMapNotEmpty,
        setKatexMapNotEmpty,
        markdownMapNotEmpty,
        setMarkdownMapNotEmpty
      }}
    >
      <Router>
        <AppContainer>
          <Switch>
            <Route path="/create-account">
              <CreateAccount />
            </Route>
            <Route path="/signup/confirm">
              <ConfirmSignup />
            </Route>
            <Route path="/signup/">
              <Signup />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/resetpassword">
              <ForgotPasswordPage />
            </Route>

            <Route path="/ready">
              <AccountReady />
            </Route>

            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </AppContainer>
      </Router>
    </AppContext.Provider>
  );
}

function About() {
  return <h2>About</h2>;
}
