export default {
    id:'pastel1',
    name:'Pastel 1',
    document: {
        backgroundColor: "#fff",
        shadowColor: "black",
        shadow: false,

    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#DD9391',
        fillColor: "#fff",
        borderWidth: "2",
        borderColor: "#ffbdbd",
        borderRadius: 4,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'rgb(126,126,239)',
        fillColor: "#fff",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 5,
        padding: 4,
    },
    connectors: {
        color: "#c9c9ff",
        width: "2",
    }
};
