import React from 'react'
import styled from 'styled-components'

const StyledBox = styled.div`
	border: solid 1px rgba(0,0,0,0.2);
	border-radius: 10px;
	padding: 1rem;
	-webkit-box-shadow: 2px 2px 33px -1px rgba(0,0,0,0.47);
-moz-box-shadow: 2px 2px 33px -1px rgba(0,0,0,0.47);
box-shadow: 2px 2px 33px -1px rgba(0,0,0,0.47);

`
export default StyledBox