import React from "react";
import { geometry } from "@hugo_volare/shared-utilities";
const { Point } = geometry;

const SVGRect = ({ layout }) => {
  const { stroke, fill, strokeWidth, borderRadius, shadow, position=new Point(0,0), size } = layout;
  const trstr = `translate(${position.x}, ${position.y})`;
  //  const filterAttribute = shadow ? { filter: "url(#shadow)" } : {};
  const filterAttribute = {};
  return (
    <rect
      width={size.width}
      height={size.height}
      stroke={stroke}
      fill={fill}
      strokeWidth={strokeWidth.toString()}
      transform={trstr}
      rx={borderRadius.toString()}
      {...filterAttribute}
    />
  );
};

export default SVGRect;
