import React from "react";
import { List, Avatar, Button, Skeleton, Card, Input, Empty, Spin } from "antd";
import {
	CenteredLayoutContainer,
	FullSizeLayoutContainer,
	ViewportLayoutContainer,
} from "../layout";
import { useHistory, Link } from "react-router-dom";

export default () => {
	return (
			<FullSizeLayoutContainer>
				<CenteredLayoutContainer>
					<Spin spinning={true}></Spin>
				</CenteredLayoutContainer>
			</FullSizeLayoutContainer>
	);
};
