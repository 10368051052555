import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  message,
  Layout,
  Card,
  PageHeader,
  Spin,
} from "antd";

import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";

import { useAppContext } from "../context/contextLib";
import {
  FullSizeLayoutContainer,
  CenteredLayoutContainer,
  StyledLink,
  LoadingIndicator,
  Box,
} from "../layout";

const { Text } = Typography;

const layout = {
  style: {},
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default () => {
  const {
    initialLocationPath,
    setInitialLocationPath,
    isUserAuthenticated,
    setIsUserAuthenticated,
    setCurrentUserId,
    temporalUserEmail,
    setTemporalUserEmail,
    setTemporalUserPassword,
  } = useAppContext();

  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [cognitoUser, setCognitoUser] = useState(null);

  //Si el usuario necesita cambiar de password:
  const [newPassword, setNewPassword] = useState("");
  const [requiresPasswordChange, setRequiresPasswordChange] = useState(false);
  const [
    requiresAccountConfirmation,
    setRequiresAccountConfirmation,
  ] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  console.log("initialLocationPath:", initialLocationPath);
  const goToInitialLocation = () => {
    history.push(initialLocationPath || "/");
  };

  useEffect(() => {
    if (isUserAuthenticated) {
      goToInitialLocation();
    }
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (requiresAccountConfirmation) {
      history.push("/signup/confirm");
    }
  }, [requiresAccountConfirmation]);

  //Referencia a form de confirmar cambio password (cuando necesita cambiarlo)
  const [changePasswordForm] = Form.useForm();

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const onFinish = async (values) => {
    console.log("Success:", values);
    setLoading(true);
    setHasError(false);
    try {
      setTemporalUserEmail(email);
      const user = await Auth.signIn(email, password);
      setCognitoUser(user);
      /* Cuando el usuario es creado desde el dashboard
         necesita cambiar password */
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setRequiresPasswordChange(true);
      } else {
        setIsUserAuthenticated(true);
        setCurrentUserId(user.attributes.sub);
      }
    } catch (e) {
      /* User has not confirmed with the code */
      if (e.code && e.code === "UserNotConfirmedException") {
        setRequiresAccountConfirmation(true);
        //guardar password para que al confirmar, se loguee inmediatamente
        setTemporalUserPassword(password);
        return;
      } else {
        setErrorMessage(e.message || "Authentication failed");
        setHasError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (requiresPasswordChange) {
    //TODO: Move to separate components

    const validateConfirmPassword = (rule, value, callback) => {
      if (value && value !== changePasswordForm.getFieldValue("new-password")) {
        callback("Passwords don't match");
      } else {
        callback();
      }
    };

    /* Cambio de password
       Logueamos al usuario y asignamos:
       - cognitoUser
       - currentUserId
       - isAuthenticated
    */
    const onChangePasswordSuccess = async (values) => {
      try {
        setHasError(false);
        setInitialLocationPath('/')
        if(values['new-password'] != values['new-password-confirm']) {
          message.error("The passwords don't match!")
          return;
        }

        setLoading(true);
        const resultComplete = await Auth.completeNewPassword(cognitoUser, newPassword);
        message.success("Password changed!");
        console.log('signing in')
        const user = await Auth.signIn(resultComplete.challengeParam.userAttributes.email, newPassword)
        setCognitoUser(user);
        setCurrentUserId(user.attributes.sub)
        setIsUserAuthenticated(true);
      } catch (e) {
        //TODO: mostrar error
        console.log(e);
        alert(e.message);
        setErrorMessage(e.message || "Authentication failed");
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    const onChangePasswordFailed = (errorInfo) => {
      console.log(errorInfo);
    };

    return (
      <FullSizeLayoutContainer>
        <PageHeader
          ghost={false}
          title={"Set new Password"}
          subTitle="Please change your password to continue"
        ></PageHeader>
        <CenteredLayoutContainer>
        <Spin spinning={loading} delay={500}>

          <Card style={{ width: "500px" }} title="New Password Details">
            <LoadingIndicator visible={loading} />
            <Text type="warning">Please update your password</Text>
            <Form
              form={changePasswordForm}
              {...layout}
              name="password-change-form"
              onFinish={onChangePasswordSuccess}
              onFinishFailed={onChangePasswordFailed}
            >
              <Form.Item
                label="New Password"
                name="new-password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Confirm"
                name="new-password-confirm"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password",
                  },
                ]}
              >
                <Input.Password
                  value={newPassword}
                  onChange={(ev) => {
                    setNewPassword(ev.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Submit <LoadingIndicator visible={loading} />
                </Button>
              </Form.Item>

              <div>{hasError && <Text type="danger">{errorMessage}</Text>}</div>
            </Form>
          </Card>
          </Spin>
        </CenteredLayoutContainer>
      </FullSizeLayoutContainer>
    );
  }

  return (
    <FullSizeLayoutContainer>
      <PageHeader
        ghost={false}
        title={"Login"}
        subTitle="Please provide your credentials to continue"
      ></PageHeader>

      <CenteredLayoutContainer>
        <Spin spinning={loading} delay={500}>

        <Card style={{ width: "400px" }}>
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              type="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter your email",
                },
              ]}
            >
              <Input
                value={email}
                onChange={(ev) => {
                  setEmail(ev.target.value);
                  setTemporalUserEmail(ev.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter your password." },
              ]}
            >
              <Input.Password
                value={password}
                onChange={(ev) => {
                  setPassword(ev.target.value);
                }}
              />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit <LoadingIndicator visible={loading} />
              </Button>
            </Form.Item>
            <div>{hasError && <Text type="danger">{errorMessage}</Text>}</div>
          </Form>
          <div>
            <StyledLink w100={true} to="/resetpassword">
              Forgot password?
            </StyledLink>
          </div>
        </Card>
        </Spin>
      </CenteredLayoutContainer>
    </FullSizeLayoutContainer>
  );
};
