import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card } from "antd";
import { PageHeader, Descriptions, Input, Collapse } from "antd";
import TeamList from "../app-components/teams/teamList";
import TeamDetails from "../app-components/teams/teamDetails";
import AddTeamMember from "../app-components/teams/addTeamMember";
import TeamMemberList from "../app-components/teams/teamMemberList";
import ChangePassword from "../auth/changePassword";
import { useAppContext } from "../context/contextLib";

const {Panel} = Collapse;

const PageContentContainer = styled.div`
    background: #fff;
`
export default () => {
    const { currentTeam } = useAppContext();
    if(!currentTeam) 
        return null;

    return (
        <PageContentContainer>
            <PageHeader
                ghost={false}
                title={"User Settings"}
                subTitle={""}
            ></PageHeader>

            <Collapse>
                <Panel header="Change Password">
                     <ChangePassword />

                </Panel>
            </Collapse>
        </PageContentContainer>
    );
};
