/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTeamMembers = /* GraphQL */ `
  query GetTeamMembers($teamId: ID!) {
    getTeamMembers(teamId: $teamId) {
      userID
      isAdmin
      email
      isActive
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      groupID
      teamID
      documents {
        items {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        groupID
        teamID
        documents {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      title
      groupID
      userID
      documentType
      diagramType
      diagramCode
      theme
      projectID
      project {
        id
        name
        groupID
        teamID
        documents {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          groupID
          documentID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      nextToken
    }
  }
`;
export const getDraft = /* GraphQL */ `
  query GetDraft($id: ID!) {
    getDraft(id: $id) {
      id
      title
      userID
      documentType
      diagramType
      diagramCode
      theme
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const listDrafts = /* GraphQL */ `
  query ListDrafts(
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        userID
        documentType
        diagramType
        diagramCode
        theme
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      groupID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        documentID
        document {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRecentDocument = /* GraphQL */ `
  query GetRecentDocument($id: ID!) {
    getRecentDocument(id: $id) {
      id
      teamID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRecentDocuments = /* GraphQL */ `
  query ListRecentDocuments(
    $filter: ModelRecentDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecentDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        documentID
        document {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const docsByProject = /* GraphQL */ `
  query DocsByProject(
    $projectID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    docsByProject(
      projectID: $projectID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      nextToken
    }
  }
`;
export const recentDraftsByUser = /* GraphQL */ `
  query RecentDraftsByUser(
    $userID: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recentDraftsByUser(
      userID: $userID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        userID
        documentType
        diagramType
        diagramCode
        theme
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      nextToken
    }
  }
`;
export const recentDocumentsByTeam = /* GraphQL */ `
  query RecentDocumentsByTeam(
    $teamID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecentDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recentDocumentsByTeam(
      teamID: $teamID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamID
        documentID
        document {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      groupID
      stripeCustomer
      stripeSubscription
      stripeSubscriptionObject
      stripeProductCode
      stripeProductName
      status
      paymentStatus
      createdAt
      updatedAt
      teams {
        items {
          id
          groupID
          name
          accountID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const accountByStripeSubscription = /* GraphQL */ `
  query AccountByStripeSubscription(
    $stripeSubscription: String
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountByStripeSubscription(
      stripeSubscription: $stripeSubscription
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const accountByStripeCustomer = /* GraphQL */ `
  query AccountByStripeCustomer(
    $stripeCustomer: String
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountByStripeCustomer(
      stripeCustomer: $stripeCustomer
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTeamDetails = /* GraphQL */ `
  query GetTeamDetails($id: ID!) {
    getTeamDetails(id: $id) {
      id
      groupID
      name
      accountID
      createdAt
      updatedAt
      account {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      owner
      teamGroupMembers {
        items {
          id
          userID
          groupID
          teamID
          isAdmin
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listTeamDetailss = /* GraphQL */ `
  query ListTeamDetailss(
    $filter: ModelTeamDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        name
        accountID
        createdAt
        updatedAt
        account {
          id
          groupID
          stripeCustomer
          stripeSubscription
          stripeSubscriptionObject
          stripeProductCode
          stripeProductName
          status
          paymentStatus
          createdAt
          updatedAt
        }
        owner
        teamGroupMembers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTeamGroupMember = /* GraphQL */ `
  query GetTeamGroupMember($id: ID!) {
    getTeamGroupMember(id: $id) {
      id
      userID
      groupID
      teamID
      isAdmin
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTeamGroupMembers = /* GraphQL */ `
  query ListTeamGroupMembers(
    $filter: ModelTeamGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamGroupMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        groupID
        teamID
        isAdmin
        isActive
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getStripeCustomer = /* GraphQL */ `
  query GetStripeCustomer($id: ID!) {
    getStripeCustomer(id: $id) {
      id
      teamID
      email
      stripeID
      stripeSubscriptionObject
      createdAt
      updatedAt
    }
  }
`;
export const listStripeCustomers = /* GraphQL */ `
  query ListStripeCustomers(
    $filter: ModelStripeCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        email
        stripeID
        stripeSubscriptionObject
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByStripeCustomerId = /* GraphQL */ `
  query TeamByStripeCustomerId(
    $stripeID: String
    $sortDirection: ModelSortDirection
    $filter: ModelStripeCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByStripeCustomerId(
      stripeID: $stripeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamID
        email
        stripeID
        stripeSubscriptionObject
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
