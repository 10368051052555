//debe ser exacto a highestPriorityStyles.css pero con tags
//para incrustar en los svgs, estilos que se aplican con maxima prioridad, ej: para ajustar cosas especificas de los diagramas
export default `<style>
    .foreignBody {
        margin: 0;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-variant: tabular-nums;
        line-height: 1.5;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
    }
    .foreignContainer{
      display: inline-block;
    }
    .container{
      display: inline-block;
    }

    .svg_contents div, .svg_contents span, .svg_contents applet, .svg_contents object, .svg_contents iframe,
    .svg_contents h1, .svg_contents h2, .svg_contents h3, .svg_contents h4, .svg_contents h5, .svg_contents h6, .svg_contents p, .svg_contents blockquote, .svg_contents pre,
    .svg_contents a,  .svg_contents abbr, .svg_contents acronym, .svg_contents address, .svg_contents big, .svg_contents cite, .svg_contents code,
    .svg_contents del, .svg_contents dfn, .svg_contents em, .svg_contents img, .svg_contents ins, .svg_contents kbd, .svg_contents q, .svg_contents s, .svg_contents samp,
    .svg_contents small, .svg_contents strike, .svg_contents strong, .svg_contents sub, .svg_contents sup, .svg_contents tt, .svg_contents var,
    .svg_contents b, .svg_contents u, .svg_contents i, .svg_contents center,
    .svg_contents dl, .svg_contents dt, .svg_contents dd, 
    .svg_contents fieldset, .svg_contents form, .svg_contents label, .svg_contents legend,
    .svg_contents table, .svg_contents caption, .svg_contents tbody, .svg_contents tfoot, .svg_contents thead, .svg_contents tr, .svg_contents th, .svg_contents td,
    .svg_contents article, .svg_contents aside, .svg_contents canvas, .svg_contents details, .svg_contents embed, 
    .svg_contents figure, .svg_contents figcaption, .svg_contents footer, .svg_contents header, .svg_contents hgroup, 
    .svg_contents menu, .svg_contents nav, .svg_contents output, .svg_contents ruby, .svg_contents section, .svg_contents summary,
    .svg_contents time, .svg_contents mark, .svg_contents audio,.svg_contents video {
      margin: 0;
      padding: 0;
      border: 0;
      vertical-align: baseline;
    }

    h1,h2,h3,h4,h5,h6{
      margin:0px;
    }
     g{
        -webkit-font-smoothing: antialiased;
    }



html, body, div, article, aside, section, main, nav, footer, header, form, fieldset, legend, pre, code, a, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, blockquote, figcaption, figure, textarea, table, td, th, tr, input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="text"], input[type="url"], .border-box {
    box-sizing: border-box;
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
</style>`

