import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Layout } from "antd";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/contextLib";
import { useHistory } from "react-router-dom";
import { PageHeader, Descriptions, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import CaptureEmail from "../auth/captureEmail";
import CapturePaymentDetails from "../auth/capturePaymentDetails";
import RegisterUser from "../auth/registerUser";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";



//TODO: Parametros, stripe key y create customer endpoint url

const stripePromise = loadStripe("pk_test_51HEhQCBhW031b1kgAMjIFRBN5cTyxIATw0I7eXnXO0OGHdq5TfVgUF7IBvYtzWSTStl8Jup0znNcqiHPh9eZO8Pq00zBrFHGyb");


/* Proceso de creación de cuenta
   - Capturar email del usuario
   - Verificar que no haya usuarios con ese correo
   - Capturar información de pago y registro de pago
   - Solicitar contraseña para crear el usuario
*/

export default (props) => {
    const { id } = useParams();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    //TODO: Selector de price items
    const [priceId, setPriceId] = useState("price_1HEhTVBhW031b1kgD4UNgo2D");
    const [customerId, setCustomerId] = useState('');
    const [step, setStep] = useState("CAPTURE_PAYMENT_DETAILS");
    const {} = useAppContext();

    const handleEmailChanged = async (email) => {
        setEmail(email);
        //Verificar que email sea valido y crear stripe customer
        const customerId = await createStripeCustomer(email, setError);
        if(customerId){
            setStep("CAPTURE_PAYMENT_DETAILS")
        }
    };

    const onSubscriptionComplete = (subscription) => {
        alert('lista la subscripción');
    }


    return (
        <Layout>
            <PageHeader
                ghost={false}
                title={"New Account"}
                subTitle=""
            ></PageHeader>
            {error.length > 0 && <div>{error}</div>}
            {step === "CAPTURE_EMAIL" && (
                <CaptureEmail onEmailChanged={handleEmailChanged} />
            )}

            {step === "CAPTURE_PAYMENT_DETAILS" && (
                <Elements stripe={stripePromise}>
                  <CapturePaymentDetails customerId={customerId}
                  priceId={priceId}
                  onSubscriptionComplete={onSubscriptionComplete} />
                </Elements>
            )}

            {step === "REGISTER_USER" && (
                <RegisterUser email={email} />
            )}
        </Layout>
    );
};

const createStripeCustomer = async (email, setError) => {
    try {
        console.log('creating customer')
        const {customerId} = await createStripeCustomerRequest(email);
        console.log('stripe customer id:', customerId);
        return customerId

    }catch(e){
        setError(e.message);
        console.log(e);
        return false;
    }
};


const createStripeCustomerRequest = async (email)=>{
  console.log('creating stripe customer');
  const response = await fetch(' https://5dgqhhr9d4.execute-api.us-east-1.amazonaws.com/test/stripe-customer', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type':'application/json'
    }, 
    body: JSON.stringify({email: email})
  }).then(resp => resp.json())
  return response;
}
