import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
    List,
    Avatar,
    Button,
    Skeleton,
    Card,
    Layout,
    message,
    Tooltip,
} from "antd";
import { useParams, useHistory } from "react-router-dom";
import { useAppContext } from "../context/contextLib";
import { PageHeader, Descriptions, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import CaptureEmail from "../auth/captureEmail";
import CapturePaymentDetails from "../auth/capturePaymentDetails";
import RegisterUser from "../auth/registerUser";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import PriceSelector from "../app-components/account/priceSelector";

import {
    CenteredLayoutContainer,
    StyledLink,
    LoadingIndicator,
    ErrorMessage,
    Box,
} from "../layout";

//TODO: Parametros, stripe key y create customer endpoint url

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const Container = styled(Layout)`
    height: 100%;
    padding-top: 2rem;
`;

const ContentContainer = styled(Layout)`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;

    .ant-radio-inner, .ant-checkbox-inner  {
        border-color: #10406d;
    }
`;

const StyledSubmitButton = styled(Button)`
    width: 400px;
    margin-top: 1rem;
`;

export default (props) => {
    const { currentTeam, loadTeams, selectedPriceId } = useAppContext();
    const history = useHistory();
    console.log("account id:", currentTeam.account.id);

    const handleSubmit = async () => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
            //Enviar la account id para saber a que cuenta pertenece en webhook
            clientReferenceId: currentTeam.account.id,
            lineItems: [
                // Replace with the ID of your price
                { price: selectedPriceId, quantity: 1 },
            ],
            mode: "subscription",
            successUrl: window.location.href+"ready",
            cancelUrl: window.location.href,
        });
    };

    return (
        <Container>
            <CenteredLayoutContainer>
                <Layout>
                    <PageHeader
                        ghost={false}
                        title={"New Account"}
                        subTitle=""
                    ></PageHeader>
                    <ContentContainer>
                        <PriceSelector />
                        <Tooltip
                
                            title={ selectedPriceId == null ? "Please select a Plan":""}
                        >
                            <StyledSubmitButton
                                type="primary"
                                disabled={selectedPriceId == null}
                                onClick={handleSubmit}
                            >
                                Submit
                            </StyledSubmitButton>
                        </Tooltip>
                    </ContentContainer>
                </Layout>
            </CenteredLayoutContainer>
        </Container>
    );
};
