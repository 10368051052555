var md = require('markdown-it')({
        html: false,
        linkify: true,
        typography: true
      }).use(require('markdown-it-imsize')); 

export default (markdownExpressions,theme,cache)=>{
    const dynamic_styles = `
                  <style>
                    .svg_contents{
                      font-size: ${theme.nodes.fontSize}px;
                      color: ${theme.nodes.textColor};
                    }
                    .svg_contents table, .svg_contents th, .svg_contents td {
                      border: 1px solid ${theme.nodes.textColor};
                    }
                  </style>
              `;
    const map = new Map();

    var elem = document.querySelector("#container_md")

    if(!elem) {
        elem = document.createElement("div")
        elem.id = 'container_md';
        elem.style.transform = "scale(0)";
        elem.style.position = "absolute";
        

        var contents = document.createElement("div")
        contents.className = "svg_contents"; 
        contents.style.display = "table";
        contents.style.whiteSpace = "nowrap";
        contents.style.margin = 0;
        contents.style.padding = 0;
        contents.style.border = 0;
        contents.style.fontSize = theme.nodes.fontSize;
        
        elem.appendChild(contents);
        document.body.appendChild(elem);  
    }
    //debugger;
    for (let i = 0; i < markdownExpressions.length; i++) {
        var md_rendered_code = md.render(markdownExpressions[i]);
        var html_code = null;
        var contentElement = null;

        html_code = "<div id='contents'>"+md_rendered_code+"</div>";
        html_code = dynamic_styles + html_code;
        contentElement = elem.childNodes[0];
        contentElement.innerHTML = html_code;
        
        let expressionData = {
                size: {
                    width:contentElement.offsetWidth,
                    height:contentElement.offsetHeight
                }, 
                md_html: contentElement.outerHTML
        };
        map.set(markdownExpressions[i], expressionData);
    }
    return map;

}