/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateDocumentWithId = /* GraphQL */ `
  subscription OnUpdateDocumentWithId($id: ID!) {
    onUpdateDocumentWithId(id: $id) {
      id
      title
      groupID
      userID
      documentType
      diagramType
      diagramCode
      theme
      projectID
      project {
        id
        name
        groupID
        teamID
        documents {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          groupID
          documentID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject {
    onCreateProject {
      id
      name
      groupID
      teamID
      documents {
        items {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject {
    onUpdateProject {
      id
      name
      groupID
      teamID
      documents {
        items {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject {
    onDeleteProject {
      id
      name
      groupID
      teamID
      documents {
        items {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument($owner: String!) {
    onCreateDocument(owner: $owner) {
      id
      title
      groupID
      userID
      documentType
      diagramType
      diagramCode
      theme
      projectID
      project {
        id
        name
        groupID
        teamID
        documents {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          groupID
          documentID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument($owner: String!) {
    onUpdateDocument(owner: $owner) {
      id
      title
      groupID
      userID
      documentType
      diagramType
      diagramCode
      theme
      projectID
      project {
        id
        name
        groupID
        teamID
        documents {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          groupID
          documentID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument($owner: String!) {
    onDeleteDocument(owner: $owner) {
      id
      title
      groupID
      userID
      documentType
      diagramType
      diagramCode
      theme
      projectID
      project {
        id
        name
        groupID
        teamID
        documents {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          groupID
          documentID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const onCreateDraft = /* GraphQL */ `
  subscription OnCreateDraft($owner: String!) {
    onCreateDraft(owner: $owner) {
      id
      title
      userID
      documentType
      diagramType
      diagramCode
      theme
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const onUpdateDraft = /* GraphQL */ `
  subscription OnUpdateDraft($owner: String!) {
    onUpdateDraft(owner: $owner) {
      id
      title
      userID
      documentType
      diagramType
      diagramCode
      theme
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const onDeleteDraft = /* GraphQL */ `
  subscription OnDeleteDraft($owner: String!) {
    onDeleteDraft(owner: $owner) {
      id
      title
      userID
      documentType
      diagramType
      diagramCode
      theme
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      groupID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      groupID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      groupID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRecentDocument = /* GraphQL */ `
  subscription OnCreateRecentDocument($owner: String!) {
    onCreateRecentDocument(owner: $owner) {
      id
      teamID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateRecentDocument = /* GraphQL */ `
  subscription OnUpdateRecentDocument($owner: String!) {
    onUpdateRecentDocument(owner: $owner) {
      id
      teamID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteRecentDocument = /* GraphQL */ `
  subscription OnDeleteRecentDocument($owner: String!) {
    onDeleteRecentDocument(owner: $owner) {
      id
      teamID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount {
    onCreateAccount {
      id
      groupID
      stripeCustomer
      stripeSubscription
      stripeSubscriptionObject
      stripeProductCode
      stripeProductName
      status
      paymentStatus
      createdAt
      updatedAt
      teams {
        items {
          id
          groupID
          name
          accountID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount {
    onUpdateAccount {
      id
      groupID
      stripeCustomer
      stripeSubscription
      stripeSubscriptionObject
      stripeProductCode
      stripeProductName
      status
      paymentStatus
      createdAt
      updatedAt
      teams {
        items {
          id
          groupID
          name
          accountID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount {
    onDeleteAccount {
      id
      groupID
      stripeCustomer
      stripeSubscription
      stripeSubscriptionObject
      stripeProductCode
      stripeProductName
      status
      paymentStatus
      createdAt
      updatedAt
      teams {
        items {
          id
          groupID
          name
          accountID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateTeamDetails = /* GraphQL */ `
  subscription OnCreateTeamDetails($owner: String) {
    onCreateTeamDetails(owner: $owner) {
      id
      groupID
      name
      accountID
      createdAt
      updatedAt
      account {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      owner
      teamGroupMembers {
        items {
          id
          userID
          groupID
          teamID
          isAdmin
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTeamDetails = /* GraphQL */ `
  subscription OnUpdateTeamDetails($owner: String) {
    onUpdateTeamDetails(owner: $owner) {
      id
      groupID
      name
      accountID
      createdAt
      updatedAt
      account {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      owner
      teamGroupMembers {
        items {
          id
          userID
          groupID
          teamID
          isAdmin
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTeamDetails = /* GraphQL */ `
  subscription OnDeleteTeamDetails($owner: String) {
    onDeleteTeamDetails(owner: $owner) {
      id
      groupID
      name
      accountID
      createdAt
      updatedAt
      account {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      owner
      teamGroupMembers {
        items {
          id
          userID
          groupID
          teamID
          isAdmin
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateTeamGroupMember = /* GraphQL */ `
  subscription OnCreateTeamGroupMember($owner: String) {
    onCreateTeamGroupMember(owner: $owner) {
      id
      userID
      groupID
      teamID
      isAdmin
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateTeamGroupMember = /* GraphQL */ `
  subscription OnUpdateTeamGroupMember($owner: String) {
    onUpdateTeamGroupMember(owner: $owner) {
      id
      userID
      groupID
      teamID
      isAdmin
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteTeamGroupMember = /* GraphQL */ `
  subscription OnDeleteTeamGroupMember($owner: String) {
    onDeleteTeamGroupMember(owner: $owner) {
      id
      userID
      groupID
      teamID
      isAdmin
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateStripeCustomer = /* GraphQL */ `
  subscription OnCreateStripeCustomer {
    onCreateStripeCustomer {
      id
      teamID
      email
      stripeID
      stripeSubscriptionObject
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStripeCustomer = /* GraphQL */ `
  subscription OnUpdateStripeCustomer {
    onUpdateStripeCustomer {
      id
      teamID
      email
      stripeID
      stripeSubscriptionObject
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStripeCustomer = /* GraphQL */ `
  subscription OnDeleteStripeCustomer {
    onDeleteStripeCustomer {
      id
      teamID
      email
      stripeID
      stripeSubscriptionObject
      createdAt
      updatedAt
    }
  }
`;
