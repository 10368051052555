import React, {useState} from 'react'
import styled from 'styled-components'
import { Menu, Dropdown } from 'antd';
import { useAppContext } from "../../context/contextLib";
import { DownOutlined, DeploymentUnitOutlined} from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';
import { useHistory, Switch, Route, useLocation } from "react-router-dom";

export default () => {
      const {
    logout,
    isAdmin
  } = useAppContext();

  const history = useHistory();

  const [visible, setVisible] = useState(false)


  const handleVisibleChange = visible => {
    setVisible(visible)
  };

  const handleMenuClick = (e) => {
    switch(e.key){
        case "logout":
            logout();
            setVisible(false);
            break;
        case "user-settings":
           history.push('user-settings')
           break;
        case "account-settings":
           history.push('account-settings');
           break;
        break;
    }
  }

    const menu = (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="user-settings"><UserOutlined/>User Settings</Menu.Item>
        {isAdmin && <Menu.Item key="account-settings"><DeploymentUnitOutlined/>Account and Billing Settings</Menu.Item>}
        <Menu.Item key="logout"><LogoutOutlined/>Log out</Menu.Item>
      </Menu>
    );


    return (
        <Dropdown
        overlay={menu}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          <UserOutlined/> User <DownOutlined />
        </a>
      </Dropdown>
    )
}