import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  message,
  Layout,
  Card,
  PageHeader,
  Modal,
  Spin,
} from "antd";
import { Auth } from "aws-amplify";
import { useAppContext } from "../context/contextLib";
import { Link, useHistory } from "react-router-dom";
import StateKeys from "../StateKeys";

import {
  CenteredLayoutContainer,
  StyledLink,
  LoadingIndicator,
  Box,
  ErrorMessage,
} from "../layout";

const { Text, Title, Paragraph } = Typography;
const { confirm } = Modal;

const Container = styled(Layout)`
  height: 100%;
  background: #fff;
`;

const layout = {
  style: {},
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const getCognitoUser = async () => {
  try {
    return Auth.currentUserPoolUser();
  } catch (err) {
    return null;
  }
};

export default () => {
  const {
    isUserAuthenticated,
    setIsUserAuthenticated,
    setCurrentUserId,
    temporalUserEmail,
    setTemporalUserEmail,
    temporalUserPassword,
  } = useAppContext();

  const refInputResendEmail = useRef();

  const history = useHistory();
  const [code, setCode] = useState("");
  const [expiredCode, setExpiredCode] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  //Referencia a form de confirmar cambio password (cuando necesita cambiarlo)
  const [signupForm] = Form.useForm();
  const [requestCodeEmail, setRequestCodeEmail] = useState("");

  useEffect(() => {
    if (isUserAuthenticated) {
      history.push("/");
    }
  }, [isUserAuthenticated]);

  const requestCode = async () => {
    try {
      setHasError(false);
      setExpiredCode(false);
      setLoading(true);
      const resendEmail = await requestEmail();
      console.log("request code email:", resendEmail);
      if (!resendEmail) {
        //canceled
        return;
      }
      setTemporalUserEmail(resendEmail);
      const resultResend = await Auth.resendSignUp(resendEmail);
      console.log("resultResend", resultResend);
      const hide = message.info(
        "A new verification code was sent to your email"
      );
      setTimeout(hide, 2500);
    } catch (e) {
      setHasError(true);
      setErrorMessage(e.message || "Could not request code");
    } finally {
      setLoading(false);
    }
  };

  const ContentModal = () => (
    <Input
      type="email"
      ref={refInputResendEmail}
      onChange={(ev) => {
        console.log("val", ev.target.value);
        setRequestCodeEmail(ev.target.value);
      }}
    />
  );

  const requestEmail = () => {
    return new Promise((resolve) => {
      confirm({
        title: "Please enter your email",
        content: <ContentModal />,
        onOk: () => {
          console.log("ref.current:", refInputResendEmail.current);
          resolve(refInputResendEmail.current.state.value);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const onFinish = async (values) => {
    setHasError(false);
    setExpiredCode(false);
    setLoading(true);

    try {
      let email = temporalUserEmail;
      if (!email || email.length == 0) {
        email = await requestEmail();
      }
      const result = await Auth.confirmSignUp(email, code);
      console.log(result);

      //Log in si tenemos el pwd em memoria
      if (temporalUserPassword && temporalUserPassword.length > 0) {
        const user = await Auth.signIn(email, temporalUserPassword);
        setIsUserAuthenticated(true);
        setCurrentUserId(user.attributes.sub);
      } else {
        history.push("/");
      }
      message.success("Email verified");

      //success
    } catch (e) {
      if (e.code && e.code === "ExpiredCodeException") {
        //User needs to request another code
        setHasError(true);
        setExpiredCode(true);
        setErrorMessage(
          e.message || "Expired code, please request a code again."
        );
      } else {
        setHasError(true);
        setErrorMessage(e.message || "Could not confirm account");
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Container>
      <PageHeader
        ghost={false}
        title={"New Account"}
        subTitle="Please register to continue."
      ></PageHeader>

      <CenteredLayoutContainer>
        <Spin spinning={loading} delay={500}>
          <Card>
            <Title level={4}>Confirm email</Title>
            {!expiredCode && (
              <React.Fragment>
                <Paragraph>
                  Please enter the confirmation code sent to your email
                </Paragraph>
                <Form
                  {...layout}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Code"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the confirmation code",
                      },
                    ]}
                  >
                    <Input
                      value={code}
                      onChange={(ev) => {
                        setCode(ev.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                      Submit
                      <LoadingIndicator visible={loading} />
                    </Button>
                  </Form.Item>
                </Form>

                <Button type="link" onClick={requestCode}>
                  Request another confirmation code
                </Button>
              </React.Fragment>
            )}
            <div>{hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}</div>
            <div>
              {expiredCode && (
                <Button type="secondary" onClick={requestCode}>
                  Request another confirmation code{" "}
                </Button>
              )}
            </div>
          </Card>
        </Spin>
      </CenteredLayoutContainer>
    </Container>
  );
};
