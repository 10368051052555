import React, { useEffect, useRef } from "react";

/* Renderiza una expresion markdown con un foreign object para evitar pixelacion */
export default ({ layoutShape }) => {
  const { position, expressionInfo} = layoutShape;
  const {size, md_html} = expressionInfo;
  const trstr = `translate(${position.x}, ${position.y})`;
  //to fix the chromium bug for foreignobject zoom level on elements we could use this... TODO ...
  let devicePixelRatio = window.devicePixelRatio || 1;

  //dangerouslySetInnerHTML ahora es llamado desde el cloud y desde el desktop, verificar si necesitamos añadir proteccion contra XSS
  return (
      <foreignObject width={size.width} height={size.height} >
        <div className="foreignBody" xmlns="http://www.w3.org/1999/xhtml">
            <div className="foreignContainer"  dangerouslySetInnerHTML={{__html: md_html}}>
            </div>
        </div>
      </foreignObject>
    );
};
