/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addTeamMember = /* GraphQL */ `
  mutation AddTeamMember($email: String!, $teamId: ID!) {
    addTeamMember(email: $email, teamId: $teamId)
  }
`;
export const createStripePortalSession = /* GraphQL */ `
  mutation CreateStripePortalSession(
    $customerId: String!
    $teamId: ID!
    $returnUrl: String!
  ) {
    createStripePortalSession(
      customerId: $customerId
      teamId: $teamId
      returnUrl: $returnUrl
    ) {
      sessionUrl
      test
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      groupID
      stripeCustomer
      stripeSubscription
      stripeSubscriptionObject
      stripeProductCode
      stripeProductName
      status
      paymentStatus
      createdAt
      updatedAt
      teams {
        items {
          id
          groupID
          name
          accountID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteTeamDetails = /* GraphQL */ `
  mutation DeleteTeamDetails(
    $input: DeleteTeamDetailsInput!
    $condition: ModelTeamDetailsConditionInput
  ) {
    deleteTeamDetails(input: $input, condition: $condition) {
      id
      groupID
      name
      accountID
      createdAt
      updatedAt
      account {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      owner
      teamGroupMembers {
        items {
          id
          userID
          groupID
          teamID
          isAdmin
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteTeamGroupMember = /* GraphQL */ `
  mutation DeleteTeamGroupMember(
    $input: DeleteTeamGroupMemberInput!
    $condition: ModelTeamGroupMemberConditionInput
  ) {
    deleteTeamGroupMember(input: $input, condition: $condition) {
      id
      userID
      groupID
      teamID
      isAdmin
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteStripeCustomer = /* GraphQL */ `
  mutation DeleteStripeCustomer(
    $input: DeleteStripeCustomerInput!
    $condition: ModelStripeCustomerConditionInput
  ) {
    deleteStripeCustomer(input: $input, condition: $condition) {
      id
      teamID
      email
      stripeID
      stripeSubscriptionObject
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      groupID
      teamID
      documents {
        items {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      groupID
      teamID
      documents {
        items {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      groupID
      teamID
      documents {
        items {
          id
          title
          groupID
          userID
          documentType
          diagramType
          diagramCode
          theme
          projectID
          clientId
          createdAt
          updatedAt
          deleted
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      title
      groupID
      userID
      documentType
      diagramType
      diagramCode
      theme
      projectID
      project {
        id
        name
        groupID
        teamID
        documents {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          groupID
          documentID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      title
      groupID
      userID
      documentType
      diagramType
      diagramCode
      theme
      projectID
      project {
        id
        name
        groupID
        teamID
        documents {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          groupID
          documentID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      title
      groupID
      userID
      documentType
      diagramType
      diagramCode
      theme
      projectID
      project {
        id
        name
        groupID
        teamID
        documents {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          groupID
          documentID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const createDraft = /* GraphQL */ `
  mutation CreateDraft(
    $input: CreateDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    createDraft(input: $input, condition: $condition) {
      id
      title
      userID
      documentType
      diagramType
      diagramCode
      theme
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const updateDraft = /* GraphQL */ `
  mutation UpdateDraft(
    $input: UpdateDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    updateDraft(input: $input, condition: $condition) {
      id
      title
      userID
      documentType
      diagramType
      diagramCode
      theme
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const deleteDraft = /* GraphQL */ `
  mutation DeleteDraft(
    $input: DeleteDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    deleteDraft(input: $input, condition: $condition) {
      id
      title
      userID
      documentType
      diagramType
      diagramCode
      theme
      clientId
      createdAt
      updatedAt
      deleted
      owner
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      groupID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      groupID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      groupID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const createRecentDocument = /* GraphQL */ `
  mutation CreateRecentDocument(
    $input: CreateRecentDocumentInput!
    $condition: ModelRecentDocumentConditionInput
  ) {
    createRecentDocument(input: $input, condition: $condition) {
      id
      teamID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRecentDocument = /* GraphQL */ `
  mutation UpdateRecentDocument(
    $input: UpdateRecentDocumentInput!
    $condition: ModelRecentDocumentConditionInput
  ) {
    updateRecentDocument(input: $input, condition: $condition) {
      id
      teamID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRecentDocument = /* GraphQL */ `
  mutation DeleteRecentDocument(
    $input: DeleteRecentDocumentInput!
    $condition: ModelRecentDocumentConditionInput
  ) {
    deleteRecentDocument(input: $input, condition: $condition) {
      id
      teamID
      documentID
      document {
        id
        title
        groupID
        userID
        documentType
        diagramType
        diagramCode
        theme
        projectID
        project {
          id
          name
          groupID
          teamID
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        clientId
        createdAt
        updatedAt
        deleted
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      groupID
      stripeCustomer
      stripeSubscription
      stripeSubscriptionObject
      stripeProductCode
      stripeProductName
      status
      paymentStatus
      createdAt
      updatedAt
      teams {
        items {
          id
          groupID
          name
          accountID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      groupID
      stripeCustomer
      stripeSubscription
      stripeSubscriptionObject
      stripeProductCode
      stripeProductName
      status
      paymentStatus
      createdAt
      updatedAt
      teams {
        items {
          id
          groupID
          name
          accountID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createTeamDetails = /* GraphQL */ `
  mutation CreateTeamDetails(
    $input: CreateTeamDetailsInput!
    $condition: ModelTeamDetailsConditionInput
  ) {
    createTeamDetails(input: $input, condition: $condition) {
      id
      groupID
      name
      accountID
      createdAt
      updatedAt
      account {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      owner
      teamGroupMembers {
        items {
          id
          userID
          groupID
          teamID
          isAdmin
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateTeamDetails = /* GraphQL */ `
  mutation UpdateTeamDetails(
    $input: UpdateTeamDetailsInput!
    $condition: ModelTeamDetailsConditionInput
  ) {
    updateTeamDetails(input: $input, condition: $condition) {
      id
      groupID
      name
      accountID
      createdAt
      updatedAt
      account {
        id
        groupID
        stripeCustomer
        stripeSubscription
        stripeSubscriptionObject
        stripeProductCode
        stripeProductName
        status
        paymentStatus
        createdAt
        updatedAt
        teams {
          nextToken
        }
      }
      owner
      teamGroupMembers {
        items {
          id
          userID
          groupID
          teamID
          isAdmin
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createTeamGroupMember = /* GraphQL */ `
  mutation CreateTeamGroupMember(
    $input: CreateTeamGroupMemberInput!
    $condition: ModelTeamGroupMemberConditionInput
  ) {
    createTeamGroupMember(input: $input, condition: $condition) {
      id
      userID
      groupID
      teamID
      isAdmin
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTeamGroupMember = /* GraphQL */ `
  mutation UpdateTeamGroupMember(
    $input: UpdateTeamGroupMemberInput!
    $condition: ModelTeamGroupMemberConditionInput
  ) {
    updateTeamGroupMember(input: $input, condition: $condition) {
      id
      userID
      groupID
      teamID
      isAdmin
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createStripeCustomer = /* GraphQL */ `
  mutation CreateStripeCustomer(
    $input: CreateStripeCustomerInput!
    $condition: ModelStripeCustomerConditionInput
  ) {
    createStripeCustomer(input: $input, condition: $condition) {
      id
      teamID
      email
      stripeID
      stripeSubscriptionObject
      createdAt
      updatedAt
    }
  }
`;
export const updateStripeCustomer = /* GraphQL */ `
  mutation UpdateStripeCustomer(
    $input: UpdateStripeCustomerInput!
    $condition: ModelStripeCustomerConditionInput
  ) {
    updateStripeCustomer(input: $input, condition: $condition) {
      id
      teamID
      email
      stripeID
      stripeSubscriptionObject
      createdAt
      updatedAt
    }
  }
`;
