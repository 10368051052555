import React, { useState, useEffect } from "react";

import { Form, Input, Button, Checkbox, Typography, message, Card } from "antd";
import { Auth } from "aws-amplify";
import { useAppContext } from "../context/contextLib";
import { useHistory, Link } from "react-router-dom";
import styled from 'styled-components'

import {
    CenteredLayoutContainer,
    StyledLink,
    LoadingIndicator,
    Box,
} from "../layout";

const { Text } = Typography;

const layout = {
    style: {
        width: "450px",
    },
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Container = styled(CenteredLayoutContainer)`
    background: #fff;
`

export default () => {
    const {
       
    } = useAppContext();
    const history = useHistory();
    const onFinish = async (values) => {
        try {
            const cognitoUser = await Auth.currentUserPoolUser();
            console.log('cognitoUser', cognitoUser)
            const oldPassword = values['old-password']
            const newPassword = values['password']
            const confirmNewPassword = values['new-password'];

            if(newPassword != confirmNewPassword){
                message.error('New password and confirmation password are not the same')
                return;
            }
            await Auth.changePassword(cognitoUser, oldPassword, newPassword);
            message.info('Password updated')
            history.push('/')
        } catch (error) {
            //TODO: cambiar
            alert(error.message);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    };

    const [forgotPasswordForm] = Form.useForm();

    return (
        <Container>
            <Card title="Update Password">
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Old Password"
                        name="old-password"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the old password",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="New Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the new password",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Confirm"
                        name="new-password"
                        rules={[
                            {
                                required: true,
                                message: "Please type again the new password",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Container>
    );
};
