import React from "react";

import SVGBoxWithText from "./SVGBoxWithText";
import SVGBoxWithGroup from "./SVGBoxWithGroup";
import SVGHorizontalConnector from "./SVGHorizontalConnector";
import SVGVerticalConnector from "./SVGVerticalConnector";
import * as primitives from "./primitives";

const {
  Path,
  SVGRect,
  SVGCircle,
  SVGTextPath,
  PathFromSVGString,
  PathFromSVGStringWithLabels,
  SVGGroup,
  SVGLatexExpressionReactKatex,
  SVGMarkdownForeignExpression
} = primitives;



/* Se modifica este archivo para desktop, para que renderice
   el svg, no un div */
const defaultOptions = {
  svgZoom: 1.5
};

/* Componente de react que genera un SVG a partir de un
   objeto DiagramLayout */

const SVGRenderer = ({ layout, zoom = defaultOptions.svgZoom, theme, onClickCustom }) => {
  /* El diagram layout tiene una lista de elementos (children)
       que pueden ser de diferentes tipos */
  if (!theme) {
    throw new Error("Renderer Error: theme prop not defined");
  }

  const bbox = layout.bbox;
  //debugger;
  //El bounding box nos da info para cuadrar el viewbox
  const viewBox = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height + 10}`; //a veces se corta la linea que va por el borde, inclui un ancho arbitrario para estar seguros de que no se tape el path
  //debugger;
  return (
    <svg
      id="mainsvg"
      width={`${bbox.width * zoom}`}
      height={`${bbox.height * zoom + 10 }`} //a veces se corta la linea que va por el borde, inclui un ancho arbitrario para estar seguros de que no se tape el path
      viewBox={viewBox}
    >
      {renderChildElements(layout, theme, onClickCustom)}
    </svg>
  );
};

const renderChildElements = (layoutElem, theme, onClickCustom) => {
  const children = layoutElem.getChildren();
  /* 
    Nota sobre sombras: necesitamos un filtro #shadow-path
    para los paths, este tiene diferente el filterUnits
    que tiene que ser userSpaceOnUse
   */
   //debugger;
   //crown foot m 0 5 l 10 0 m -4 0 l 4 -5 m -4 5 l 4 5
   //double line m 0 5 l 10 0 m -4 0 m 0 -4 v 8 m 2 -8 v 8
  return (
    <React.Fragment>
      <defs>
        <marker
          id="arrow"
          viewBox="0 0 10 10"
          refX="10"
          refY="5"
          markerWidth="5"
          markerHeight="5"
          orient="auto-start-reverse"
          fill={theme.connectors.color}
        >
          <path stroke={theme.connectors.color} d="M 0 0 L 10 5 L 0 10 z" />
        </marker>




        <marker
          id="Many"
          viewBox="0 0 16 16"
          refX="15"
          refY="8"
          markerWidth="15"
          markerHeight="15"
          orient="auto-start-reverse"
        >
          <path stroke={theme.connectors.color} d="M0 8H15M15 15L2 8L15 1" fill="none" />
        </marker>

        <marker
          id="ZeroOrOne"
          viewBox="0 0 16 10"
          refX="15"
          refY="5"
          markerWidth="21"
          markerHeight="21"
          orient="auto-start-reverse"
        >

          <path d="M9 5H16" stroke={theme.connectors.color} fill="none"/>
          <circle cx="5" cy="5" r="4.5" stroke={theme.connectors.color} fill={theme.document.backgroundColor}/>
          <path d="M12 0L12 10" stroke={theme.connectors.color} fill="none"/>

        </marker>

        <marker
          id="OneOnlyOne"
          viewBox="0 0 10 14"
          refX="10"
          refY="7"
          markerWidth="13"
          markerHeight="13"
          orient="auto-start-reverse"
        >
          <path d="M0 7H4M4 7H7M4 7V0M4 7V14M7 7H10M7 7V0M7 7V14" stroke={theme.connectors.color} fill="none"/>
        </marker>

        <marker
          id="OneOrMany"
          viewBox="0 0 16 16"
          refX="15"
          refY="8"
          markerWidth="15"
          markerHeight="15"
          orient="auto-start-reverse"
        >
          <path stroke={theme.connectors.color} d="M2 1V15M2 8L15 15M2 8L15 1M2 8H0M2 8H15" fill="none" />
        </marker>

        <marker
          id="ZeroOrMany"
          viewBox="0 0 25 16"
          refX="23"
          refY="8"
          markerWidth="25"
          markerHeight="25"
          orient="auto-start-reverse"
        >
        <path d="M13 8H27M27 15L14 8L27 1" stroke={theme.connectors.color} fill="none"/>
        <path d="M13.5 7.5C13.5 10.7795 10.6254 13.5 7 13.5C3.37461 13.5 0.5 10.7795 0.5 7.5C0.5 4.22054 3.37461 1.5 7 1.5C10.6254 1.5 13.5 4.22054 13.5 7.5Z" stroke={theme.connectors.color} fill={theme.document.backgroundColor}/>

        </marker>



        


        <marker
          id="aggregation"
          viewBox="0 0 10 6"
          refX="9.3"
          refY="2.8"
          markerWidth="23"
          markerHeight="15"
          orient="auto-start-reverse"
        >
          <path stroke={theme.connectors.color} fill={theme.document.backgroundColor} stroke-width="0.7" d="M1.01614 2.82609L5 0.574341L8.98386 2.82609L5 5.07783L1.01614 2.82609Z" />
        </marker>
        <marker
          id="composition"
          viewBox="0 0 10 6"
          refX="9.7"
          refY="2.8"
          markerWidth="18"
          markerHeight="15"
          orient="auto-start-reverse"
        >
          <path stroke={theme.connectors.color} fill={theme.connectors.color} d="M1.01614 2.82609L5 0.574341L8.98386 2.82609L5 5.07783L1.01614 2.82609Z" />
        </marker>
        <marker
          id="implementation"
          viewBox="0 0 8 10"
          refX="8"
          refY="5"
          markerWidth="20"
          markerHeight="20"
          orient="auto-start-reverse"
        >
          <path stroke={theme.connectors.color} fill={theme.document.backgroundColor}  stroke-width="0.7" d="M0.750001 1.10289L7.5 5L0.750001 8.89712L0.750001 1.10289Z" />
        </marker>
        <marker
          id="dependency"
          viewBox="0 0 12 12"
          refX="11"
          refY="6"
          markerWidth="12"
          markerHeight="11"
          orient="auto-start-reverse"
        >
          <path stroke={theme.connectors.color} fill={theme.document.backgroundColor} d="M1 5.93162H10.886M10.886 5.93162L1 0.5M10.886 5.93162L1 11" />
        </marker>
        <marker
          id="inheritance"
          viewBox="0 0 8 10"
          refX="8"
          refY="5"
          markerWidth="20"
          markerHeight="20"
          orient="auto-start-reverse"
        >
          <path stroke={theme.connectors.color} fill={theme.document.backgroundColor} stroke-width="0.7" d="M0.750001 1.10289L7.5 5L0.750001 8.89712L0.750001 1.10289Z" />
        </marker>
        <marker
          id="association"
          viewBox="0 0 12 12"
          refX="11"
          refY="6"
          markerWidth="12"
          markerHeight="11"
          orient="auto-start-reverse"
        >
          <path stroke={theme.connectors.color} d="M1 5.93162H10.886M10.886 5.93162L1 0.5M10.886 5.93162L1 11" />
        </marker>


        <filter id="shadowOLD" x="-50%" y="-50%" width="250%" height="250%">
          <feDropShadow
            floodColor={theme.document.shadowColor}
            floodOpacity="0.3"
            dx="1"
            dy="1"
            stdDeviation="2"
          >
            {" "}
          </feDropShadow>
        </filter>

        <filter id="shadow-path" filterUnits="userSpaceOnUse" x={layoutElem.bbox.x} y={layoutElem.bbox.y}  width={layoutElem.bbox.width+'px'} height={layoutElem.bbox.height+'px'}>
          <feDropShadow
            floodColor={theme.document.shadowColor}
            floodOpacity="0.1"
            dx="1"
            dy="1"
            stdDeviation="1"
          >
            {" "}
          </feDropShadow>
        </filter>

        <filter id="shadow" filterUnits="userSpaceOnUse" x={layoutElem.bbox.x} y={layoutElem.bbox.y}  width={(layoutElem.bbox.width+100)+'px'} height={(layoutElem.bbox.height+100)+'px'}>
          <feDropShadow
            floodColor={theme.document.shadowColor}
            floodOpacity="0.1"
            dx="1"
            dy="1"
            stdDeviation="1"
          >
            {" "}
          </feDropShadow>
        </filter>
      </defs>

      <rect
        x={layoutElem.bbox.x}
        y={layoutElem.bbox.y}
        width={layoutElem.bbox.width}
        height={layoutElem.bbox.height}
        fill={theme.document.backgroundColor}
      />
      {children.map((c, ix) => {
        return renderElement(c, ix,0,onClickCustom);
      })}
    </React.Fragment>
  );
};



const renderElement = (layoutElem, ix, level = 0,onClickCustom) => {
  switch (layoutElem.type) {
    case "BoxWithText":
      return <SVGBoxWithText key={ix} data={layoutElem} lineNumber={layoutElem.lineNumber} onClickCustom={onClickCustom}/>;
    case "BoxWithGroup":
      return <SVGBoxWithGroup key={ix} layoutShape={layoutElem} level={level} renderElement={renderElement} lineNumber={layoutElem.lineNumber} onClickCustom={onClickCustom}/>
    case "HorizontalConnector":
      return <SVGHorizontalConnector key={ix} data={layoutElem} />;
    case "VerticalConnector":
      return <SVGVerticalConnector key={ix} data={layoutElem} />;
    case "Path":
      return <Path key={ix} pathLayout={layoutElem} />;
    case "TextPath":
      return <SVGTextPath key={ix} textPath={layoutElem} />;
    case "PathFromString":
      return <PathFromSVGString key={ix} pathLayout={layoutElem} />;
    case "PathFromStringWithLabels":
      return <PathFromSVGStringWithLabels key={ix} pathLayout={layoutElem} />;
    case "Math":
      //latex expression
      return <SVGLatexExpressionReactKatex key={ix} layoutShape={layoutElem} />;
    case "Markdown":
      //markdown expression
      return <SVGMarkdownForeignExpression key={ix} layoutShape={layoutElem} />;
      
    case "Rect":
      return (
        <SVGRect
          key={ix}
          layout={layoutElem}
        />
      );
    case "Circle":
      return (
        <SVGCircle
          key={ix}
          layout={layoutElem}
        />
      );
    case "Group":
      return (
        <SVGGroup key={ix + "-" + level} position={layoutElem.getPosition()} lineNumber={layoutElem.lineNumber} onClickCustom={onClickCustom}>
          {layoutElem.getChildren().map((c, cx) => {
            return renderElement(c, cx,0,onClickCustom);
          })}
        </SVGGroup>
      );
    default:
      throw new Error("Element type not supported:" + layoutElem.type);
  }
};

export default SVGRenderer;
