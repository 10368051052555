import React, { useState, useEffect } from "react";
import {
    List,
    Avatar,
    Button,
    Skeleton,
    Card,
    Tooltip,
    Radio,
    Table,
    Divider,
    Checkbox,
} from "antd";
import { useAppContext } from "../../context/contextLib";
import styled from "styled-components";

/* Lista de prices, el usuario selecciona el price que quiere
pagar con la subscripción

Cada price tiene: 
{
    id,
    name,
    price,
    description
}
 */

const TotalValue = styled.span`
    font-weight: 700;
    font-size: 1.3rem;
`;

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const columnsMonth = [
    {
        title: "Plan",
        dataIndex: "productName",
        render: (p, record) => (
            <div>
                <p>{record.productName}</p>
                <p>{record.productDesc}</p>
            </div>
        ),
    },
    {
        title: "Price/month",
        dataIndex: "unit_amount",
        //Quitar decimales
        render: (p) => (
            <TotalValue>
                {formatter.format(p / 100).replace(/\D00$/, "")}
            </TotalValue>
        ),
    },
];

const columnsYear = [
    {
        title: "Plan",
        dataIndex: "productName",
        render: (p, record) => (
            <div>
                <p>{record.productName}</p>
                <p>{record.productDesc}</p>
            </div>
        ),
    },
    {
        title: "Price/month",
        dataIndex: "unit_amount",
        render: (p) => formatter.format(p / 12 / 100),
    },
    {
        title: "Total/Year",
        dataIndex: "unit_amount",
        //Quitar decimales
        render: (p) => (
            <TotalValue>
                {formatter.format(p / 100).replace(/\D00$/, "")}
            </TotalValue>
        ),
    },
];

export default () => {
    const { priceList, setSelectedPriceId, selectedPriceId } = useAppContext();
    const [priceGroup, setPriceGroup] = useState("month");
    const [priceGroupItems, setPriceGroupItems] = useState([]);

    // Cuando cambia el grupo seleccionado filtrar los items
    useEffect(() => {
        const items = priceList.filter((item) => {
            /* Mostrar items con nickname TEST sólo si el flag está activado */
            if (item.nickname === "TEST" && !window.showTestPrice) return false;

            return item.interval === priceGroup;
        });
        setPriceGroupItems(items);
    }, [priceList, priceGroup]);

    const handleOnChange = (ev) => {
        setSelectedPriceId(ev.target.value);
    };

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
            if (selectedRowKeys.length > 0) {
                setSelectedPriceId(selectedRowKeys[0]);
            }
        },
        selectedRowKeys: selectedPriceId ? [selectedPriceId] : [],
    };

    const handlePriceGroupChange = (ev) => {
        setSelectedPriceId(null);
        setPriceGroup(ev.target.checked ? "year" : "month");
    };

    const selectRow = (record) => {
        console.log(record);
        setSelectedPriceId(record.id);
    };

    return (
        <Card title="Select your Subscription Plan">
            <Table
                title={() => (
                    <Checkbox onChange={handlePriceGroupChange}>
                        Yearly Payment (Discount Price)
                    </Checkbox>
                )}
                rowSelection={{
                    type: "radio",
                    ...rowSelection,
                }}
                rowKey="id"
                columns={priceGroup == "month" ? columnsMonth : columnsYear}
                dataSource={orderItems(priceGroupItems)}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => {
                        selectRow(record);
                    },
                })}
            />
        </Card>
    );
};

const PriceItem = ({ item }) => {
    return (
        <Card title={item.name}>
            <Card.Meta title={item.price} description={item.description} />
        </Card>
    );
};

/* Ordena de menor a mayor segun precio */
const orderItems = (items) => {
    const ordered = [...items];
    ordered.sort((a, b) => {
        return a.unit_amount < b.unit_amount ? -1 : 1;
    });
    return ordered;
};
