import React from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useAppContext } from "../../context/contextLib";

export default () => {
	const { currentTeam } = useAppContext();

	if (!currentTeam) return null;

    /* En este control poner opciones futuras para gestión de Teams */
	return (
		<div>

        </div>
	);
};
