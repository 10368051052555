import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Empty } from "antd";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/contextLib";
import ListDocuments from "../app-components/documents/listDocuments";
import { useHistory } from "react-router-dom";
import { PageHeader, Descriptions, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";

import emptyBackground from "../assets/graphics/undraw_empty_street_sfxm.svg";


export default (props) => {
    const { id } = useParams();
    const history = useHistory();
    const [editingName, setEditingName] = useState(false);

    const { loadMyDrafts, myDrafts } = useAppContext();

    useEffect(() => {
        loadMyDrafts();
    }, []);

    const handleNewDiagramClick = () => {
        history.push("/new-draft");
    };

    return (
        <div>
            <PageHeader
                ghost={false}
                title={"My Drafts"}
                subTitle="Drafts are not shared with the team."
                extra={[
                    <Button type="primary" onClick={handleNewDiagramClick}>
                        New Diagram
                    </Button>,
                ]}
            ></PageHeader>
            {myDrafts.length > 0 && (
                <Card title="Documents">
                    <ListDocuments documents={myDrafts} isDraft={true} />
                </Card>
            )}
            {myDrafts.length === 0 && (
                <Card>
                    <Empty
                        image={emptyBackground}
                        imageStyle={{
                            height: 200,
                        }}
                        description={<span>Project created.</span>}
                    >
                        <Button type="primary" onClick={handleNewDiagramClick}>
                            Add a new Diagram
                        </Button>
                    </Empty>
                </Card>
            )}
        </div>
    );
};
