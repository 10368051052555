import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Layout, Menu, Breadcrumb, message } from "antd";

/* Container for pages that have a centered panel */
const CenteredFullSizeLayout = styled(Layout)`
	height: 100%;
	width: 100%;
	display:flex;
	align-items:center;
	justify-content: center;
`;

export default CenteredFullSizeLayout