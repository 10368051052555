export default {
    id:'blueback',
    name:'Blue-b',
    document: {
        backgroundColor: "#fff",
        shadowColor: "black",
        shadow: false,

    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#fff',
        fillColor: "#6593F5",
        borderWidth: "1.4",
        borderColor: "#24438F",
        borderRadius: 2,
        padding: 8,
        // backgroundColor: "#6593F5"
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'#24438F',
        fillColor: "#fff",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 5,
        padding: 4,
    },
    connectors: {
        color: "#24438F",
        width: "1.4",
    }
};
