import React, {useCallback,useRef} from 'react';
import {geometry} from '@hugo_volare/shared-utilities'

const {Point} = geometry;

const Group = ({ position=new Point(0,0), children, shadow=false, lineNumber, onClickCustom }) => {
  const onClickHandler = ()=> {
    onClickCustom(lineNumber)
  }
  const trstr = `translate(${position.x}, ${position.y})`;  
  //Por ahora desactivamos shadow
  //const filterAttribute = shadow ? { filter: "url(#shadow)" } : {};
  const filterAttribute = {};
  let debugRect = <rect x="0" y="0" fill="red" width="4" height="4"/>

  if (lineNumber!== undefined && onClickCustom!==undefined){
     return <g transform={trstr} {...filterAttribute} onClick={onClickHandler} >{children}</g>;
  }else{
     return <g transform={trstr} {...filterAttribute} >{children}</g>;
  }
};


export default Group;