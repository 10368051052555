/* Componente que renderiza un rectangulo y un texto centrado dado el x,y
   Asume que el x,y corresponde a la parte superior izquierda 

   */
import React from "react";
import { SVGGroup, SVGRect, Text } from "./primitives";

/**
    Elemento que pinta un rectangulo
    y renderiza el grupo
    @renderElement: Metodo con el que se renderizaran los items del grupo
    */
const SVGBoxWithGroup = ({ layoutShape, renderElement, onClickCustom}) => {
  

    const {
        fillColor,
        borderWidth,
        borderColor,
        borderRadius,
        shadow,
        lineNumber,
    } = layoutShape;

    const rectLayout = {
        strokeWidth: borderWidth,
        stroke: borderColor,
        size: layoutShape.getSize(),
        borderRadius: borderRadius,
        fill: fillColor,
        shadow: shadow
    };

    return (
        <SVGGroup position={layoutShape.getPosition()} shadow={shadow} lineNumber={lineNumber} onClickCustom={onClickCustom} >
            <SVGRect layout={rectLayout} />
            <SVGGroup position={layoutShape.getGroupPosition()} shadow={false}>
                {layoutShape.group.getChildren().map((c, cx) => {
                    return renderElement(c, cx ,0 ,onClickCustom);
                  })}
            </SVGGroup>
        </SVGGroup>
    );
};

export default SVGBoxWithGroup;
