import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Card, Form, Input, message, Spin } from "antd";
import { useAppContext } from "../../context/contextLib";
import { useHistory } from "react-router-dom";

export default () => {
	const { currentTeam, loadProjects, addProject, projects } = useAppContext();

	const history = useHistory();
	const form = Form.useForm();
	const [loading, setLoading] = useState(false);

	const onFinish = async (values) => {
		setLoading(true);
		try {
			const project = await addProject({
				name: values.projectName,
				teamID: currentTeam.id,
				groupID: currentTeam.groupID,
			});
			if (project) {
				history.push("/project/" + project.id);
			}
			message.success("New project created")
		} catch (e) {
			message.error(
				"Error: Could not create project, please try again later."
			);
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Spin spinning={loading} delay={400}>
			<Card style={{ width: "500px", border: "none" }}>
				<Form onFinish={onFinish}>
					<Form.Item
						name="projectName"
						label="Project Name"
						rules={[{ required: true }]}
					>
						<Input placeholder="Name" />
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit">
							Create Project
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</Spin>
	);
};
