export default {
    id:'pink',
    name: 'pink',
    document: {
        backgroundColor: "#7BC8F6",
        shadowColor: "black",
        shadow: false,
    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#1B2431',
        fillColor: "#FEB2D0",
        borderWidth: "2",
        borderColor: "#A7FFB5",
        borderRadius: 2,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'#1B2431',
        fillColor: "#7BC8F6",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 0,
        padding: 2,
    },
    connectors: {
        color: "#A7FFB5",
        width: "2",
    }
};
