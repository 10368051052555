import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, PageHeader, Layout } from "antd";
import RecentProjects from "../app-components/projects/recentProjects";
import SearchProjects from "../app-components/projects/searchProjects";
import AddProjectForm from "../app-components/projects/addProjectForm";
import {
    CenteredLayoutContainer,
    HorizontallyCenteredLayoutContainer,
    StyledLink,
    LoadingIndicator,
    Box,
} from "../layout";


const Container = styled(Layout)`

background: #fff;
`

export default () => {
	return (
		<Container>
			<PageHeader
				ghost={false}
				title={"New Project"}
				subTitle=""
				extra={[]}
			></PageHeader>

			<AddProjectForm />
		</Container>
	);
};
