/* State keys 
   Throws an error when trying to access
   a non existent key.
*/

const keys = [
	"USER_IS_AUTHENTICATED",
	"LOGIN_SIGNUP_USER_EMAIL"
]

const enumObj  = {}
keys.forEach((key)=> {
	enumObj[key] = key
})

const handler = {
	get: function(target, prop, receiver){
		if(!target[prop]){
			throw new Error("Key not defined:"+prop)
		}
		return target[prop]
	}
}

const enumProxy = new Proxy(enumObj, handler)

export default enumProxy;