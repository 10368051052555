import React from "react";
import { layout } from "@hugo_volare/diagrams-layout-lib";
import { geometry } from "@hugo_volare/shared-utilities";
const  COMMANDS  = layout.shapes.PathCommands;

const { Point } = geometry;
/* Genera un path svg a partir de un objeto layout/shapes/Path */

const Path = ({ pathLayout }) => {
  if (!pathLayout) return null;
  let commands = pathLayout.getCommands();
  let parts = commands.map(c => getSVGPathFragment(c));
  let pathData = parts.join(" ");
  let markerEnd = pathLayout.hasEndArrow() ? { markerEnd: `url(#arrow)` } : {};
  let markerStart = pathLayout.hasStartArrow() ? { markerStart: `url(#arrow)` } : {};
  const filterAttribute = pathLayout.shadow
    ? { filter: "url(#shadow-path)" }
    : {};

  return (
    <path
      d={pathData}
      fill={pathLayout.fill || "none"}
      stroke={pathLayout.stroke}
      strokeDasharray={pathLayout.getStrokeDashArray()}
      strokeWidth={pathLayout.strokeWidth}
      {...markerEnd}
      {...filterAttribute}
      {...markerStart}
    />
  );
};

/* Dado un comando ej LINE_TO, retorna el fragmento de la cadena
   de svg que representa ese comando */
function getSVGPathFragment(command) {
  let parts = [];
  switch (command.getCommandId()) {
    case COMMANDS.MOVE_TO:
      parts.push(`M ${command.getTarget().x},${command.getTarget().y}`);
      break;

    case COMMANDS.LINE_TO:
      parts.push(`L ${command.getTarget().x} ${command.getTarget().y}`);
      break;

    case COMMANDS.CURVE_TO:
      parts.push(
        `Q ${command.getControlPoint().x} ${command.getControlPoint().y}, ${
          command.getTarget().x
        } ${command.getTarget().y} `
      );
      break;
    case COMMANDS.CUBIC_CURVE_TO:
      let cp1 = command.getControlPoint1();
      let cp2 = command.getControlPoint2();
      let target = command.getTarget();
      parts.push(
        `C ${cp1.x} ${cp1.y}, ${cp2.x} ${cp2.y}  ${target.x} ${target.y} `
      );
      break;
    case COMMANDS.CLOSE_PATH:
      parts.push("Z");
      break;
    default:
      throw new Error("Path command not supported:", command.getCommandId());
  }

  return parts.join(" ");
}

export default Path;
