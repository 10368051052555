import React, { useEffect } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Input } from "antd";
import { useAppContext } from "../../context/contextLib";
import ExplorerProjectItem from './explorerProjectItem'

export default () => {
	const { projects } = useAppContext();

	return (
		<Card>
			<List
				grid={{
					gutter: 16,
					xs: 1,
					sm: 2,
					md: 4,
					lg: 4,
					xl: 6,
					xxl:6,
				}}
				dataSource={projects}
				renderItem={(item) => (
					<List.Item>
						<ExplorerProjectItem item={item} />
					</List.Item>
				)}
			/>
		</Card>
	);
};
