import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Form, Input, Spin } from "antd";
import { useAppContext } from "../../context/contextLib";

export default () => {
	const { currentTeam, addTeamMember } = useAppContext();
	const [loading, setLoading] = useState(false)
	const [form] = Form.useForm();

	if (!currentTeam) {
		return null;
	}

	const formLayout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 8 },
	};

	const buttonItemLayout = {
		wrapperCol: { span: 14, offset: 4 },
	};

	const onFinish = async () => {
		const email = form.getFieldValue("email");
		setLoading(true);
		await addTeamMember({ email: email, teamId: currentTeam.id });
		setLoading(false);
		form.setFieldsValue({ email: "" });
	};

	const onFinishFailed = (err) => {
		console.log(err);
	};

	return (
		<Card title="Add Team Member">
			<Spin spinning={loading}>
			<Form
				layout={"inline"}
				form={form}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Form.Item
					label="Email"
					name="email"
					rules={[{ type: "email" }]}
				>
					<Input placeholder="User email" />
				</Form.Item>

				<Form.Item {...buttonItemLayout}>
					<Button type="primary" htmlType="submit">
						Add User
					</Button>
				</Form.Item>
			</Form>
			</Spin>
		</Card>
	);
};
