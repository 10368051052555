import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, message } from "antd";
import { PageHeader, Descriptions, Input } from "antd";
import TeamList from "../app-components/teams/teamList";
import TeamDetails from "../app-components/teams/teamDetails";
import AddTeamMember from "../app-components/teams/addTeamMember";
import TeamMemberList from "../app-components/teams/teamMemberList";
import { useAppContext } from "../context/contextLib";
import {Link} from 'react-router-dom';

const PageContentContainer = styled.div`
    background: #fff;
`


export default () => {
    const { currentTeam, isAdmin, createStripeBillingPortalSession } = useAppContext();
    if(!currentTeam || !isAdmin) 
        return null;

    const openBillingPortal = async ()=>{

       
    }

    return (
        <PageContentContainer>
            <PageHeader
                ghost={false}
                title={"Team"}
                subTitle={currentTeam.name}
                extra={[
                    <Link to="/account-settings">Account Settings and Billing </Link>
                ]}
            ></PageHeader>
            
            <TeamDetails />
            <AddTeamMember />
            <TeamMemberList />
        </PageContentContainer>
    );
};
