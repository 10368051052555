import React from "react";
import { List, Card } from "antd";
import { themes } from "../../themes";
import styled from "styled-components";

const ThemeItem = styled.div`
    width: 100px;
    height: 30px;
    border: solid ${props => props.theme.nodes.borderWidth}px
        ${props => props.theme.nodes.borderColor};
    background-color: ${props => props.theme.nodes.fillColor};
    color: ${props => props.theme.nodes.textColor};
    font-family: ${props => props.theme.nodes.fontFamily};
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
`;

const ThemeList = styled.ul`
    white-space: nowrap;
    margin: 3px 0;
    
`;

const ThemeBackground = styled.div`
    padding: 4px;
    display: inline-flex;
    background-color: ${props => props.fill};
    &:hover {
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    }
    margin-right: 4px;

`;
/* Selector de tema, cada tema tiene un id, name, theme */
export default ({ selectedTheme, handleItemClick }) => {
    return (
        <ThemeList>
            {themes.map(item => (
                <ThemeBackground key={item.id}
                    onClick={() => handleItemClick(item)}
                    fill={item.document.backgroundColor}
                >
                    <ThemeItem selected={selectedTheme == item} theme={item}>
                        {item.name}
                    </ThemeItem>
                </ThemeBackground>
            ))}
        </ThemeList>
    );
};
