import React from 'react';
import { List, Avatar, Button, Skeleton, Card, Input, Empty } from "antd";
import CenteredContainer from '../layout/centeredLayoutContainer'
import { useHistory, Link } from "react-router-dom";


export default()=>{
	return <CenteredContainer>
		<Card title="Account Ready">
		
		 	Your Account has been activated.
		 	<p>
		 		<Link to="/">Home</Link>
		 	</p>

		</Card>
	</CenteredContainer>
}