import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
	List,
	Avatar,
	Button,
	Skeleton,
	Card,
	Typography,
	Layout,
	PageHeader,
} from "antd";
import RecentProjects from "../app-components/projects/recentProjects";
import SearchProjects from "../app-components/projects/searchProjects";
import { useAppContext } from "../context/contextLib";
import { useHistory } from "react-router-dom";
import diagramTemplates from "../assets/diagrams/templates";
import icons from "../assets/diagrams/icons";
import templates from "../assets/diagrams/templates";

const diagramTypes = [
	{
		type: "graph",
		name: "Graph",
		icon: icons["graph"],
		template: templates["graph"],
	},
	{
		type: "sequence",
		name: "Sequence",
		icon: icons["sequence"],
		template: templates["sequence"],
	},
	{
		type: "mind-map",
		name: "Mind Map",
		icon: icons["mind-map"],
		template: templates["mind-map"],
	},
	{
		type: "timeline",
		name: "Timeline",
		icon: icons["timeline"],
		template: templates["timeline"],
	},
	{
		type: "flowchart",
		name: "Flow chart",
		icon: icons["flowchart"],
		template: templates["flowchart"],
	},
	{
		type: "tree",
		name: "Tree (Vertical)",
		icon: icons["tree"],
		template: templates["tree"],
	},
	{
		type: "tree-left-to-right",
		name: "Tree (Horizontal)",
		icon: icons["tree-left-to-right"],
		template: templates["tree-left-to-right"],
	},
	{
		type: "state-machine",
		name: "State Machine",
		icon: icons["state-machine"],
		template: templates["state-machine"],
	},
	{
		type: "onion-layers",
		name: "Onion Layers",
		icon: icons["onion-layers"],
		template: templates["onion-layers"],
	},
	{
		type: "system-layers",
		name: "System Layers",
		icon: icons["system-layers"],
		template: templates["system-layers"],
	},
	{
		type: "stack",
		name: "Layer Stack",
		icon: icons["stack"],
		template: templates["stack"],
	},
	{
		type: "entity-relationship",
		name: "Entity Relationship",
		icon: icons["entity-relationship"],
		template: templates["entity-relationship"],
	},
	{
		type: "class_diag",
		name: "Class Diagram",
		icon: icons["class_diag"],
		template: templates["class_diag"],
	},
	{
		type: "dataflow",
		name: "Data Flow Diagram",
		icon: icons["dataflow"],
		template: templates["dataflow"],
	},
];

const PageLayout = styled(Layout)`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding: 4rem;
	background: #6c6e6f;
	overflow: auto;
`;

const PageContentsContainer = styled(Card)`
	max-width: 1200px;
	min-width: 250px;
	background: rgb(255, 255, 255);
	border-radius: 10px;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(201, 201, 201, 1) 100%
	);
`;

const GenericListContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 1rem;
`

export default ({ isDraft }) => {
	const {
		currentTeam,
		currentProject,
		addDocument,
		addDraft,
	} = useAppContext();

	//TODO: cambios cuando isDraft es true

	const history = useHistory();
	const clickHandler = async (item) => {
		if (isDraft) {
			//Crear diagrama nuevo y redirigir usuario a pagina de diagrama
			const newDoc = await addDraft({
				title: "(New Draft)",
				documentType: "diagram",
				diagramType: item.type,
				diagramCode: item.template,
				theme: "default",
			});
			console.log("new draft created:", newDoc);
			history.push("/draft/" + newDoc.id);
		} else {
			//Crear diagrama nuevo y redirigir usuario a pagina de diagrama
			const newDoc = await addDocument({
				title: "(New Document)",
				documentType: "diagram",
				diagramType: item.type,
				diagramCode: item.template,
				projectID: currentProject.id,
				groupID: currentProject.groupID,
				theme: "default",
			});
			console.log("new document created:", newDoc);
			history.push("/document/" + newDoc.id);
		}
	};

	return (
		<PageLayout>
			<PageContentsContainer>
				<PageHeader
					ghost={false}
					title={"New Diagram"}
					subTitle="Please select the type of diagram you want to create"
				></PageHeader>
				<GenericListContainer>
					{diagramTypes.map((item) => (
						<DiagramItem
							item={item}
							key={item.type}
							clickHandler={() => {
								clickHandler(item);
							}}
						/>
					))}
				</GenericListContainer>
			</PageContentsContainer>
		</PageLayout>
	);
};

const CoverImage = styled.img`
	height: 100px;
	padding: 1rem;
	background: #1890ff4f;
`;

const DiagramCard = styled(Card)`
	width: 200px;
	min-height: 120px;
	padding: 0;
	border: solid 1px #cecece;
	display: inline-flex;
	align-items: center;
	margin-right: 1rem;
	margin-bottom:1rem;

	.ant-card-body {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	img {
		width: 100px;
		height: 40px;
		object-fit: contain;
	}
`;

const DiagramItem = ({ item, clickHandler }) => {
	return (
		<DiagramCard hoverable onClick={clickHandler}>
			<img src={item.icon} alt={item.name} />
			<h1> {item.name} </h1>
		</DiagramCard>
	);
};
