import React, { useEffect } from "react";
import { Layout, Menu, Breadcrumb, Button, Card } from "antd";
import {
	UserOutlined,
	LaptopOutlined,
	NotificationOutlined,
	FormOutlined,
} from "@ant-design/icons";
import { useAppContext } from "../context/contextLib";
import StateKeys from "../StateKeys";
import { Auth } from "aws-amplify";
import { useHistory, Switch, Route, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
	FullSizeLayoutContainer,
	StyledLink,
	LoadingIndicator,
	Box,
} from "../layout";

import { ListProjects, AddProject } from "../app-components/projects";
import TeamSettings from "./teamSettings";
import ProjectExplorer from "./projectExplorer";
import NewDiagramOptions from "./newDiagramOptions";
import NewProjectOptions from "./newProjectOptions";
import ProjectHome from "./projectHome";
import DiagramHome from "./diagramHome";
import InactiveAccount from "./inactiveAccount";
import DraftHome from "./draftHome";
import AccountSettings from "./accountSettings";
import UserHome from "./userHome";
import Drafts from "./drafts";
import UserSettings from "./userSettings";
import CompletePaymentAccount from "./completePaymentAccountCreated";
import UserDropDown from "../app-components/user/userDropDown";
import RoamExtensionInfo from './roamExtensionInfo';

import {
	HomeOutlined,
	TeamOutlined,
	ProjectOutlined,
	SettingFilled,
	SyncOutlined,
	LoadingOutlined,
	ProfileFilled
} from "@ant-design/icons";



const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const StyledHeader = styled(Header)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const StyledSider = styled(Sider)`
	background: #fff;
`;

export default () => {
	const {
		setInitialLocationPath,
		isUserAuthenticated,
		setIsUserAuthenticated,
		temporalUserEmail,
		setTemporalUserEmail,
		isAdmin,
		currentTeam,
	} = useAppContext();

	const location = useLocation();

	const history = useHistory();

	const goToLogin = (path) => {
		setInitialLocationPath(path);
		history.push("/login");
	};

	useEffect(() => {
		const path = location.pathname;

		if (!isUserAuthenticated) {
			goToLogin(path);
		}
	}, [isUserAuthenticated]);

	if (!isUserAuthenticated) {
		return null;
	}

	const handleMenuItemClick = ({ key }) => {
		switch (key) {
			case "team-settings":
				history.push("/team");
				break;
				break;
			case "projects":
				history.push("/projects");
				break;

				break;
			case "home":
				history.push("/");
				break;

				break;
			case "drafts":
				history.push("/drafts");
				break;
			case "roam":
				history.push("/roam-extension");
				break;
			case "gptintegration":
				history.push("/gptintegration");

			default:
				break;
		}
	};

	console.log("currentTeam:", currentTeam);

	if (currentTeam && currentTeam.account.status === "INACTIVE") {
		return <InactiveAccount />;
	}

	if (
		currentTeam &&
		currentTeam.account.status === "JUST_CREATED" &&
		currentTeam.account.paymentStatus === "PENDING"
	) {
		return <CompletePaymentAccount />;
	}

	/* Sólo mostrar link Team para producto TEAM y BUSINESS */
	const isTeamManager =
		isAdmin &&
		["TEAM", "BUSINESS"].indexOf(currentTeam.account.stripeProductCode) >=
			0;

	//TODO: Sacar Sider a componente separado.
	return (
		<FullSizeLayoutContainer>
			<Layout>
				<StyledSider>
					<div className="logo" />

					<Menu
						onClick={handleMenuItemClick}
						theme="light"
						mode="vertical"
						defaultSelectedKeys={["explorer"]}
					>
						<Menu.Item key="home">
							<HomeOutlined />
							Home
						</Menu.Item>
						<Menu.Item key="drafts">
							<FormOutlined />
							Drafts
						</Menu.Item>
						<Menu.Item key="projects">
							<ProjectOutlined />
							Projects
						</Menu.Item>
						{isTeamManager && (
							<Menu.Item key="team-settings">
								<TeamOutlined />
								Team
							</Menu.Item>
						)}
						<Menu.Item>
							{isUserAuthenticated && <UserDropDown />}
						</Menu.Item>
				

						
					</Menu>
				</StyledSider>
				<Layout>
					<Content
						className="site-layout-background"
						style={{
							margin: 0,
							minHeight: 280,
						}}
					>
						<Switch>
							<Route exact path="/team">
								<TeamSettings />
							</Route>
							<Route exact path="/projects">
								<ProjectExplorer />
							</Route>
							<Route exact path="/new">
								<NewDiagramOptions />
							</Route>
							<Route exact path="/new-draft">
								<NewDiagramOptions isDraft={true} />
							</Route>
							<Route exact path="/new-project">
								<NewProjectOptions />
							</Route>

							<Route exact path="/project/:id">
								<ProjectHome />
							</Route>

							<Route exact path="/document/:id">
								<DiagramHome />
							</Route>

							<Route exact path="/draft/:id">
								<DraftHome />
							</Route>

							<Route exact path="/drafts">
								<Drafts />
							</Route>

							<Route exact path="/user-settings">
								<UserSettings />
							</Route>

							<Route exact path="/account-settings">
								<AccountSettings />
							</Route>
							
						</Switch>
					</Content>
				</Layout>
			</Layout>
		</FullSizeLayoutContainer>
	);
};
