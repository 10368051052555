import React, { useState, useEffect } from "react";

import { Form, Input, Button, Checkbox, Typography } from "antd";
import { Auth } from "aws-amplify";
import { useAppContext } from "../context/contextLib";
import { useHistory } from "react-router-dom";
import ResetForgottenPassword from "./resetForgottenPassword";

import {
  CenteredLayoutContainer,
  StyledLink,
  LoadingIndicator,
  Box,
} from "../layout";

const { Text, Link, Title} = Typography;

const layout = {
  style: {

  },
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default () => {
const {
  isUserAuthenticated,
  setIsUserAuthenticated,
  temporalUserEmail,
  setTemporalUserEmail,
} = useAppContext();

  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);


  const onFinish = async () => {
    setLoading(true)
    try {
      await Auth.forgotPassword(temporalUserEmail);
      setCodeSent(true);
    } catch (error) {
      //TODO: mostrar mensaje
      alert(error.message);
    } finally {
      setLoading(false)
    }
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };

  if (codeSent) {
    return <ResetForgottenPassword />;
  }
  return (
    <CenteredLayoutContainer>

      <Box>
        <Text> Password Reset </Text>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email"
            name="email"
            type="email"
            value={temporalUserEmail}
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter your email",
              },
            ]}
          >
            <Input
              value={temporalUserEmail}
              onChange={(ev) => {
                setTemporalUserEmail(ev.target.value);
              }}
            />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Box>
    </CenteredLayoutContainer>
  );
};
