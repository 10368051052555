/* DiagramPreview
   Componente que renderiza un objeto de tipo DiagramLayout
*/
import React, { useState, useEffect, useCallback, useRef } from "react";
import diagramRegistry from "@hugo_volare/diagrams-layout-lib";
import { SVGRenderer } from "@hugo_volare/diagrams-svg-renderer";
import styled from "styled-components";


const defaultOptions = {
  zoomFactor: 1.0
};

const PreviewBackground = styled.div`
  background: #ebebeb;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`;

const Document = styled.div`
  background-color: ${props => props.fill};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  -webkit-box-shadow: -1px 1px 5px 0px rgba(69, 68, 69, 0.47);
  -moz-box-shadow: -1px 1px 5px 0px rgba(69, 68, 69, 0.47);
  box-shadow: -1px 1px 5px 0px rgba(69, 68, 69, 0.47);
`;



const DiagramPreview = ({
  diagramLayout,
  zoomFactor = defaultOptions.zoomFactor,
  theme,
  onClickGroupElement,
  onRequestZoomChange,
}) => {

  //Variables para manejar el scroll
  //Solución inspirada en: https://medium.com/creative-technology-concepts-code/native-browser-touch-drag-using-overflow-scroll-492dc92ac737
  const refContainer = useRef();
  const [isDragging, setIsDragging] = useState(false)
  const [dragInitialPos, setDragInitialPos] = useState(null)
  const [dragDiff, setDragDiff] = useState(null)
  //para hacer el zoom centrado en el punto actual necesitamos guardarlo
  const [mousePos, setMousePos] = useState(null)

  const mouseDownHandler = useCallback((ev)=>{
    setIsDragging(true)
    refContainer.current.focus()
    setDragInitialPos({
          x:ev.clientX + refContainer.current.scrollLeft,
          y:ev.clientY + refContainer.current.scrollTop
        })
    

  }, [])

  const mouseMoveHandler = useCallback((ev)=>{
    const posX = ev.clientX + refContainer.current.scrollLeft
    const posY = ev.clientY + refContainer.current.scrollTop

    setMousePos({x:ev.offsetX, y:ev.offsetY})

    if(!isDragging){
      return;
    }
 
    const diff = {
      x: dragInitialPos.x - posX,
      y: dragInitialPos.y - posY
    }

    refContainer.current.scrollLeft += diff.x;
    refContainer.current.scrollTop += diff.y
    setDragDiff(diff)

  }, [isDragging, dragInitialPos])

  const mouseUpHandler = useCallback((ev)=>{
    setIsDragging(false)
    //dragDiff me da la dirección, 

  }, [dragDiff])

  const blurHandler = useCallback((ev)=>{
    setIsDragging(false)
  })


  const onWheelHandler = useCallback((ev)=>{
     if(ev.ctrlKey){
        const deltaZoom = -ev.deltaY/100;
        const newZoom = zoomFactor + deltaZoom
        onRequestZoomChange(newZoom)
      }
  }, [zoomFactor])

  //Necesitamos capturar el mouseup global y cancelar el dragging
  //De lo contrario si el usuario suelta el mouse por fuera, sigue isDragging en true
  useEffect(()=>{

    const handler = ()=>{
      setIsDragging(false);
    }
    window.addEventListener('mouseup', handler)
    //Al retornar dentro de useEffect se pueden limpiar subscripciones etc.
    return function cleanUp(){
      window.removeEventListener('mouseup', handler)
    }
  }, [])

  

  if (diagramLayout == null) return null;
  if (theme == null) return null;

  const docPadding = 80;
  const docWidth = `${diagramLayout.bbox.width * zoomFactor + docPadding}px`;
  const docHeight = `${diagramLayout.bbox.height * zoomFactor + docPadding}px`;
  return (
    <PreviewBackground ref={refContainer}
     onMouseDown={mouseDownHandler} 
     onMouseUp={mouseUpHandler}
     onMouseMove={mouseMoveHandler}
     onBlur={blurHandler}
     onWheel={onWheelHandler}
     style={{cursor:isDragging?'grabbing':''}}
     >
      <Document fill={theme.document.backgroundColor} style={{ width: docWidth, height: docHeight }}>
        <SVGRenderer zoom={zoomFactor} layout={diagramLayout} theme={theme} onClickCustom={onClickGroupElement} />
      </Document>
    </PreviewBackground>
  );
};

export default DiagramPreview;
