import React from 'react'
import styled from 'styled-components'
import { Form, Input, Button, Checkbox, Typography } from "antd";
const { Text } = Typography;


const StyledContainer = styled(Text)`
	width: 100%;
	display: flex;
	justify-content: center
`

export default (props)=>(<StyledContainer type="danger" {...props}/>)