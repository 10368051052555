/* Componente que renderiza un rectangulo y un texto centrado dado el x,y
   Asume que el x,y corresponde a la parte superior izquierda 

   */

import { linkVertical } from "d3-shape";

import React from "react";

/**
    Renderiza un conector a partir del objeto
    layout/shapes/Connector
    */
const SVGVerticalConnector = ({ data }) => {
    //Usamos el verticalLink
    const from = data.getSource();
    const to = data.getTarget();
    const link = linkVertical();
    let path = link({ source: from.vec, target: to.vec });
    const filterAttribute = data.shadow
    ? { filter: "url(#shadow-path)" }
    : {};

    return (
        <path
            fill="none"
            stroke="black"
            stroke={data.color}
            strokeWidth={data.width}
            d={path}
            {...filterAttribute}
        />
    );
};

export default SVGVerticalConnector;
