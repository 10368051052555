import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

const StyledLink = styled(Link)`
	padding: 0 1rem;
	display:inline-flex;
	width: ${({w100})=>{
		return w100 ? '100%': 'auto'
	}};
	justify-content:center;
`

export default StyledLink