export default {
    id:'orange',
    name: 'Orange',
    document: {
        backgroundColor: "#fff",
        shadowColor: "black",
        shadow: false,

    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#FD6A02',
        fillColor: "#fff",
        borderWidth: "1.4",
        borderColor: "#FD6A02",
        borderRadius: 6,
        padding: 6,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'#FD6A02',
        fillColor: "#fff",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 0,
        padding: 4,
    },
    connectors: {
        color: "#FD6A02",
        width: "1.4",
    }
};
