import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Layout, Menu, Breadcrumb, message } from "antd";

const FullSizeLayout = styled(Layout)`
	height: 100%;
	width: 100%;
	background: #fff;
`;

export default FullSizeLayout