import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  List,
  Avatar,
  Button,
  Skeleton,
  Card,
  Input,
  PageHeader,
  Descriptions,
  Layout,
  Drawer,
  Popconfirm,
  Spin,
  Modal,
  Typography,
  Badge,
  Divider,
} from "antd";
import { useDebounceCallback } from "@react-hook/debounce";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/contextLib";
import { useHistory } from "react-router-dom";
import { EditOutlined, ThunderboltOutlined } from "@ant-design/icons";
import ShareSnapshotButton from "../app-components/diagrams/shareSnapshotButton";
import EditorContents from "../app-components/diagrams/editorContents";
import QuickReferenceDrawer from "../app-components/diagrams/quickReferenceDrawer";
import { LoadingContent } from "../layout";
import registry from "@hugo_volare/diagrams-layout-lib";
import { Auth, API } from "aws-amplify";

const TextArea = Input.TextArea;
const Text = Typography.Text;

const DiagramEditorContainer = styled(Layout)`
  display: flex;
  height: 100%;
`;

const TitleInputChange = styled(Input)`
  width: 300px;
`;

export default (props) => {
  const { id } = useParams();
  const {
    loadDraft,
    currentDraft,
    setCurrentDraft,
    updateDraft,
    deleteDraft,
    setSelectedTheme,
  } = useAppContext();

  setSelectedTheme(null);

  const {} = useAppContext();
  const [editingName, setEditingName] = useState(false);
  const history = useHistory();
  const [lastDraftIdLoaded, setLastDraftIdLoaded] = useState();
  const [docsVisible, showDocs] = useState(false);
  const [activeAIModal, setActiveAIModal] = useState(false);
  const [aiPrompt, setAIPrompt] = useState("");

  const refInputName = useRef();

  const debouncedUpdate = useDebounceCallback((doc) => {
    console.log("update doc");
    updateDraft(doc);
  }, 1000);

  const onCloseDocs = () => {
    showDocs(false);
  };

  //Cargar el diagrama
  useEffect(() => {
    //Aqui resetear estado
    (async () => {
      //Si el diagrama fue recien creado
      //entonces estará en currentDiagram y no
      //debemos cargarlo
      let doc = currentDraft;
      if (!currentDraft || currentDraft.id != id) {
        doc = await loadDraft(id);
      }
      setAIPrompt("");
      //Registrar visita a documento
      //TODO: limitarlo para que sólo se haga si el mismo día no hay visitas al mismo doc
    })();
  }, [id]);

  //log when aiPrompt changes
  useEffect(() => {
    console.log("aiPrompt changed:", aiPrompt);
  }, [aiPrompt]);

  //Mover focus a input cuando se cambie el nombre
  useEffect(() => {
    if (editingName) {
      setTimeout(() => {
        if (refInputName.current) {
          refInputName.current.focus();
        }
      }, 5);
    }
  }, [editingName]);

  const onCodeChangeHandler = (newValue) => {
    console.log("code changed:", newValue);
    const updatedDoc = { ...currentDraft, diagramCode: newValue };
    setCurrentDraft(updatedDoc);
    debouncedUpdate(updatedDoc);
  };

  const handleTitleChange = (ev) => {
    const updatedDoc = { ...currentDraft, title: ev.target.value };
    setCurrentDraft(updatedDoc);
  };

  const goToDrafts = () => {
    history.push("/drafts");
  };

  const onThemeChangeHandler = (newValue) => {
    console.log("theme changed:", newValue);
    const updatedDoc = { ...currentDraft, theme: newValue };
    setCurrentDraft(updatedDoc);
    debouncedUpdate(updatedDoc);
  };

  const commitUpdateTitle = () => {
    updateDraft(currentDraft);
    setEditingName(false);
  };

  const handleDeleteDraft = () => {
    deleteDraft(currentDraft);
    history.push("/drafts");
  };

  const handleClickChangeName = () => {
    setEditingName(true);
  };

  const showRefDoc = () => {
    showDocs(true);
    console.log("show docs");
  };

  const showAIModal = () => {
    setActiveAIModal(true);
  };

  //TODO: Manejar loading
  if (!currentDraft) return <LoadingContent />;

  const title = editingName ? (
    <form onSubmit={commitUpdateTitle}>
      <TitleInputChange
        ref={refInputName}
        placeholder="Draft name"
        value={currentDraft.title}
        onChange={handleTitleChange}
      />
    </form>
  ) : (
    currentDraft.title
  );

  return (
    <DiagramEditorContainer>
      <PageHeader
        onBack={goToDrafts}
        ghost={false}
        title={title}
        subTitle={
          editingName ? null : (
            <Button type="link" onClick={handleClickChangeName}>
              <EditOutlined />
              Rename
            </Button>
          )
        }
        extra={[
          new URLSearchParams(window.location.search).get('test') ? (
            <Button
              onClick={showAIModal}
              type="dashed"
              icon={<ThunderboltOutlined />}
            >
              Generate with AI &nbsp;
              <Badge status="warning" />
              Experimental
            </Button>
          ) : null,
          <ShareSnapshotButton type="primary" />,
          <Button onClick={showRefDoc}>Quick reference</Button>,

          <Popconfirm
            placement="top"
            title={"Delete Draft?"}
            onConfirm={handleDeleteDraft}
            okText="Yes"
            cancelText="No"
          >
            <Button> Delete </Button>
          </Popconfirm>,
        ]}
      ></PageHeader>

      <QuickReferenceDrawer
        diagramType={currentDraft.diagramType}
        onClose={onCloseDocs}
        visible={docsVisible}
      />
      <GPTModal
        diagramType={currentDraft.diagramType}
        aiPrompt={aiPrompt}
        setAIPrompt={setAIPrompt}
        visible={activeAIModal}
        onClose={() => setActiveAIModal(false)}
        onDiagramReady={(val) => {
          onCodeChangeHandler(val);
        }}
      />
      <EditorContents
        diagramCode={currentDraft.diagramCode}
        diagramType={currentDraft.diagramType}
        loadedTheme={currentDraft.theme}
        onCodeChange={onCodeChangeHandler}
        onThemeChange={onThemeChangeHandler}
      />
    </DiagramEditorContainer>
  );
};

const OpenAIKeyPrompt = ({ visible, onClose }) => {
  //A component to prompt the user for the openAI key
  //and store it in the local storage and nothing else
  //it will be used by the GPTModal

  const [openAIKey, setOpenAIKey] = useState("");
  useEffect(() => {
    setOpenAIKey(localStorage.getItem("openAIKey") || "");
  }, []);

  const handleSave = (val) => {
    localStorage.setItem("openAIKey", val.trim());
    setOpenAIKey(val);
  };

  return (
    <div>
      <Text>The key is stored only on your device.</Text>
      <Input
        placeholder="OpenAI Key"
        value={openAIKey}
        onChange={(ev) => handleSave(ev.target.value)}
      />
    </div>
  );
};

const GPTModal = ({
  aiPrompt,
  diagramType,
  setAIPrompt,
  visible,
  onClose,
  onDiagramReady,
}) => {
  //Get the diagram description from the type:
  const diagramModule = registry.getModule(diagramType);
  const diagramDescription = diagramModule?.name || diagramType;
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOpenAIKeyPrompt, setShowOpenAIKeyPrompt] = useState(false);

  const handleGenerate = async () => {
    setLoading(true);
    setShowError(false);
    console.log("aiPrompt", aiPrompt);

    if (
      localStorage.getItem("openAIKey") == null ||
      localStorage.getItem("openAIKey") == ""
    ) {
	  setErrorMessage("Please enter your OpenAI key and try again.");
	  setShowError(true);
      setShowOpenAIKeyPrompt(true);
      setLoading(false);
      return;
    }
	if(aiPrompt == null || aiPrompt.trim() == ""){
		setErrorMessage("Please enter a diagram description and try again.");
		setShowError(true);
		setLoading(false);
		return;
	}
    //Try to generate the code for the diagram
    API.post("ai", "", {
      body: {
        inputText: aiPrompt,
        //Get openAIKey from localStorage
        openai_key: localStorage.getItem("openAIKey"),
        type: "text-to-diagram",
        diagramType: diagramType,
      },
    })
      .then((resp) => {
        console.log("resp", resp);
        const diagramObject = resp.response;
        if (
          diagramObject.diagramCode == null ||
          diagramObject.diagramCode == ""
        ) {
          setShowError(true);
          setErrorMessage("Sorry, we encountered an error.");
          console.log("error, empty diagram code");
        }
        onDiagramReady(diagramObject.diagramCode);
        setLoading(false);
		onClose();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setShowError(false);
    onClose();
  };

  return (
    <Modal
      title={`${diagramDescription} diagram AI Generation`}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleGenerate}>
          Generate
        </Button>,
      ]}
    >
      <Button
        block
		type="dashed"
        onClick={() => setShowOpenAIKeyPrompt(!showOpenAIKeyPrompt)}
      >
        Set OpenAI Key{showOpenAIKeyPrompt ? "-" : "+"}
      </Button>
      {showOpenAIKeyPrompt && (
        <OpenAIKeyPrompt
          visible={showOpenAIKeyPrompt}
          onClose={() => setShowOpenAIKeyPrompt(false)}
        />
      )}
	  <br/>
      <Spin spinning={loading} tip={"Diagram generation in process.."}>
        <Text style={{ marginBottom: 16, display: "block" }}>
          Please enter a description for the <b>{diagramDescription} diagram</b>{" "}
          you want to generate.
        </Text>
        <TextArea
          placeholder="Diagram description"
          value={aiPrompt}
          onChange={(e) => setAIPrompt(e.target.value)}
          rows={4}
        />
      </Spin>
      {showError && (
        <Text type="danger">{errorMessage}</Text>
      )}
    </Modal>
  );
};

export {GPTModal, OpenAIKeyPrompt};