import React, { useEffect, useRef } from "react";
/* Renderiza una expresion latex  */
export default ({ layoutShape }) => {
  //const ref = useRef();
  const { position, expressionInfo} = layoutShape;
  const {size, md_html} = expressionInfo
  const trstr = `translate(${position.x}, ${position.y})`;
  //debugger;
  return (
    <foreignObject width={size.width} height={size.height} >
        <div className="foreignBody" xmlns="http://www.w3.org/1999/xhtml">
            <div className="foreignContainer"  dangerouslySetInnerHTML={{__html: md_html}}>
            </div>
        </div>
      </foreignObject>
  );
};
