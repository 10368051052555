import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Tooltip } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import diagramIcons from '../../assets/diagrams/icons'
import dayjs from 'dayjs'

import icon from "../../assets/graphics/undraw_files_6b3d.svg"
var relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime);

const { Meta } = Card;

const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    text-align: center;
  }

  img {
    width: 60px;
  }
`;

const StyledCard = styled(Card)`
  width: 224px;
  height: 177px;
`;

const CoverContainer = styled.div`
  height: 80px;
  overflow: hidden;
  background-color: #eaeaea;
  padding: 5px;
  font-size: 0.5rem;
  position: relative;
  text-align: center;

    img {
      height: 100%;
    }

    pre{
      position: absolute;
      top: 0;
      right: 0;
      padding-right:5px;
    }
`;


const HeaderImage = ()=>{
    return (
        <img src={icon} />
    )
}


/*     cover={
          <CoverContainer>
            <HeaderImage/>
          </CoverContainer>
        }
*/
export default ({ item, isDraft=false }) => {
  return (
    <Link to={isDraft? `/draft/${item.id}`: `/document/${item.id}`}>
      <StyledCard
        hoverable={true}
        size="default"
        cover={
                  <CoverContainer>
                    <HeaderImage/>
                  </CoverContainer>
                }
      >
        <Meta
          avatar={<Avatar src={diagramIcons[item.diagramType]} />}
          title={
            <Tooltip title={item.title}>
              <span>{item.title}</span>
            </Tooltip>
          }
          description={dayjs(item.updatedAt).fromNow()}
        />
      </StyledCard>
    </Link>
  );
};
