export default {
    id:'grapefruit',
    name: 'Grapefruit',
    document: {
        backgroundColor: "#fff",
        shadowColor: "black",
        shadow: false,
    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#000',
        fillColor: "#FDC1C5",
        borderWidth: "2",
        borderColor: "#107AB0",
        borderRadius: 2,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'#107AB0',
        fillColor: "#fff",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 0,
        padding: 2,
    },
    connectors: {
        color: "#107AB0",
        width: "2",
    }
};
