import React from "react";
import { geometry } from "@hugo_volare/shared-utilities";
const { Point } = geometry;

const SVGCircle = ({ layout }) => {
  const { radius,stroke, fill, strokeWidth, shadow, position=new Point(0,0), size } = layout;
  const trstr = `translate(${position.x}, ${position.y})`;
  const filterAttribute = shadow ? { filter: "url(#shadow)" } : {};
  return (
    <circle 
    cx = {position.x + radius}
    cy = {position.y + radius}
    r={radius} 
    stroke={stroke} 
    strokeWidth={strokeWidth.toString()} 
    fill={fill} 
    {...filterAttribute}/>
  );
};

export default SVGCircle;
