import React, { useEffect, useState, useRef } from "react";
import { PageHeader, Descriptions, Input, Card } from "antd";
import styled from "styled-components";
import "../../node_modules/video-react/dist/video-react.css";
import { Player } from 'video-react';

const StyledInstructions = styled.ol`
    li{
        list-style-type: decimal;
        padding: 0.2rem 0;
        margin-left: 1rem;
    }

    textarea#embedcode {
        width: 80%;
        padding: 1rem;
        height: 150px;
        margin: 0.5rem 0;
    }
`

const VideoCard = styled(Card)`
    width: 80%;
`

export default (props) => {
    const setupCode = `var s = document.createElement('script')
s.type = "text/javascript"
s.src ="https://roam-integration-pro.diagram.codes/diagram-codes.js?v=0.1"
s.async = true
document.getElementsByTagName('head')[0].appendChild(s)
`;
    return (
        <div>
            <PageHeader
                ghost={false}
                title={"Extension for Roam Research (Experimental)"}
                subTitle="Install the extension and use Diagram.Codes with Roam Research."
            ></PageHeader>
            <Card>
                <p>
                    <a href="https://roam-extension.diagram.codes" target="_blank">How does it work?</a>
                </p>
                <p>
                    If you are a Roam Research User you can install the Digram.Codes extension using the following steps:
                </p>

                <StyledInstructions>
                    <li>Create a new page to store the setup information</li>
                    <li>
                        In the new page add a new <span class="roam-action">{"{{roam/js}}"}</span> block
                    </li>
                    <li>
                        A warning button will be displayed. Click on the "Yes I know what I'm doing" button.
                    </li>
                    <li>
                       Add a nested code block by writing ```
                    </li>
                    <li>
                        Copy the following code and paste it into the code-block contents:
                        
                        <textarea id="embedcode" readonly="" value={setupCode}></textarea>

                    </li>
                    <li>Refresh Roam</li>
                </StyledInstructions>

            </Card>

            <VideoCard>
                <Player
                  playsInline
                  poster="/roam-setup-instructions-thumbnail.png"
                  src="https://d1plbtapjewfq8.cloudfront.net/assets/setup-roam-resized.mp4"
                />
            </VideoCard>
        </div>
    );
};
