import React, { useState } from "react";

import styled from "styled-components";
import { Form, Input, Button, Checkbox, Typography, Card } from "antd";
import { Auth } from "aws-amplify";
import { useAppContext } from "../context/contextLib";
import { Link, useHistory } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import {
  CenteredLayoutContainer,
  StyledLink,
  LoadingIndicator,
  ErrorMessage,
  Box,
} from "../layout";

import PriceSelector from "../app-components/account/priceSelector";

const createSubscriptionEndpoint =
  process.env.REACT_APP_CREATE_SUBSCRIPTION_ENDPOINT;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  button {
    align-self: center;
    margin-top: 2rem;
  }
`;
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardContainer = styled.div`
  margin-bottom: 16px;
`;

const ErrorContainer = styled.div`
  margin-top: 16px;
  color: red;
`;

const { Text } = Typography;

const ContainerCard = styled(Card)`
  width: 500px;
  margin-top: 1rem;
`;

const layout = {
  style: {},
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default ({ customerId, priceId, onSubscriptionComplete }) => {
  const { currentTeam } = useAppContext();

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [stripeError, setStripeError] = useState("");
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    await handleOnPlaceOrder();
    setLoading(false);
  };

  const handleOnPlaceOrder = async () => {
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    setStripeError("");
    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    );

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setStripeError(error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem("latestInvoiceId");
        console.log("retrying payment for invoice:", invoiceId);
        try {
          const { subscription } = await retryInvoiceWithNewPaymentMethod({
            stripe,
            customerId,
            paymentMethodId,
            invoiceId,
            priceId,
          });

          onSubscriptionComplete(subscription);
        } catch (e) {
          setStripeError(e);
        }
      } else {
        // Create the subscription
        try {
          const { subscription } = await createSubscription({
            stripe,
            customerId,
            paymentMethodId,
            priceId,
          });
          //Lista la subscripción
          console.log(subscription);
          onSubscriptionComplete(subscription);
        } catch (err) {
          setStripeError(err.message || err);
        }
      }
    }
  };

  function createSubscription({ customerId, paymentMethodId, priceId }) {
    console.log("customerId:", customerId);
    return (
      fetch(createSubscriptionEndpoint, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
    );
  }

  function retryInvoiceWithNewPaymentMethod({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
  }) {
    return (
      fetch("/retry-invoice", {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
      // No more actions required. Provision your service for the user.
    );
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      throw { error: { message: "Your card was declined." } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  return (
    <>
      <PriceSelector />
      <ContainerCard title="Payment Method">
        <StyledForm onSubmit={handleSubmit}>
          <CardContainer>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </CardContainer>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!stripe || loading}
          >
            Process payment
          </Button>

          <div>{stripeError && stripeError.message}</div>
        </StyledForm>
      </ContainerCard>
    </>
  );
};
