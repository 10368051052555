import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Tooltip } from "antd";
import dayjs from 'dayjs'
import {
    EditOutlined,
    EllipsisOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import projectIcon from '../../assets/graphics/undraw_tabs_jf82.svg'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime);



const { Meta } = Card;

const ContainerItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .label {
        text-align: center;
    }

    img {
        width: 100%;
    }
`;

const StyledCard = styled(Card)``;

const CoverContainer = styled.div`
    height: 80px;
    overflow: hidden;
    background-color: #eaeaea;
    padding: 5px;
    font-size: 0.5rem;
    display: flex;

    img {
        width:100%;
    }

    
`;


const HeaderImage = ()=>{
    return (
        <img src={projectIcon} />
    )
}

export default ({ item }) => {
    return (
        <Link to={`/project/${item.id}`}>
            <StyledCard
                hoverable={true}
                size="default"
                cover={
                    <CoverContainer>
                        <HeaderImage/>
                    </CoverContainer>
                }
            >
                <Meta
                    title={
                        <Tooltip title={item.name}>
                            <span>{item.name}</span>
                        </Tooltip>
                    }
                  description={''}
                />
            </StyledCard>
        </Link>
    );
};
