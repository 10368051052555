export default {
    id:'green',
    name:'Green',
    document: {
        backgroundColor: "#fff",
        shadowColor: "black",
        shadow: false,

    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#3D9911',
        fillColor: "#fff",
        borderWidth: "1.4",
        borderColor: "#3D9911",
        borderRadius: 2,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'#3D9911',
        fillColor: "#fff",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 5,
        padding: 4,
    },
    connectors: {
        color: "#3D9911",
        width: "1.4",
    }
};
