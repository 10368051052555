/* Componente que renderiza un rectangulo y un texto centrado dado el x,y
   Asume que el x,y corresponde a la parte superior izquierda 

   */
import React from "react";
import { SVGGroup, SVGRect, Text } from "./primitives";

/**
    box: Shape De tipo BoxWithText
    El tamano del rectangulo es width(text) + 2*padding
    tenemos que calcular el tamano del texto aqui?
    */
const SVGBoxWithText = ({ data, onClickCustom }) => {
    const box = data;
    const {
        fontFamily,
        fontSize,
        fillColor,
        borderWidth,
        borderColor,
        borderRadius,
        shadow,
        textColor,
        fontWeight,
        lineNumber
    } = box;

    const rectLayout = {
        strokeWidth: borderWidth,
        stroke: borderColor,
        size: box.getSize(),
        borderRadius: borderRadius,
        fill: fillColor,
        shadow: shadow
    };

    return (
        <SVGGroup position={box.getPosition()} shadow={shadow} lineNumber={lineNumber} onClickCustom={onClickCustom}>
            <SVGRect layout={rectLayout} />
            <Text
                position={box.getLabelPosition()}
                fontSize={fontSize}
                textColor={textColor}
                fontFamily={fontFamily}
                fontWeight={fontWeight}
            >
                {box.getLabel()}
            </Text>
        </SVGGroup>
    );
};

export default SVGBoxWithText;
