import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
`

export default ({ children }) => <StyledContainer>
{children}
<Footer>
    <a href="https://oss-attribution.diagram.codes/attribution.txt">OSS Attributions</a>
</Footer>
</StyledContainer>;
