export default {
    id:'cornflower',
    name:'Cornflower',
    document: {
        backgroundColor: "#6593F5",
        shadowColor: "black",
        shadow: false,

    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#fff',
        fillColor: "#6593F5",
        borderWidth: "1.4",
        borderColor: "white",
        borderRadius: 2,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'#fff',
        fillColor: "#6593F5",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 5,
        padding: 4,
    },
    connectors: {
        color: "white",
        width: "1.4",
    }
};
