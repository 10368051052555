import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card } from "antd";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../context/contextLib";
import RecentProjects from "../app-components/projects/recentProjects";
import SearchProjects from "../app-components/projects/searchProjects";
import ListProjects from "../app-components/projects/listProjects";
import { PageHeader, Descriptions } from "antd";

export default () => {
  const {
    projects
  } = useAppContext();

  const history = useHistory();
  const handleNewProject = () => {
    history.push("/new-project");
  };

  return (
    <div>
      <PageHeader
        ghost={false}
        title={"Projects"}
        subTitle=""
        extra={[
          
            <Button type="primary" onClick={handleNewProject}>
              New Project
            </Button>,
        
        ]}
      ></PageHeader>

      {/*<SearchProjects/>*/} 
      <ListProjects />
    </div>
  );
};
