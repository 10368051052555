import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Tooltip } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import diagramIcons from '../../assets/diagrams/icons'
import dayjs from 'dayjs'

import icon from "../../assets/graphics/undraw_files_6b3d.svg"
var relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime);

const { Meta } = Card;
const ItemContainer = styled.div`
  background: #fff;
  padding: 0.2rem;
  border-bottom: solid 1px lightgrey;
`

const DiagramIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`
export default ({ item, isDraft=false }) => {
  return (
    <Link to={isDraft? `/draft/${item.id}`: `/document/${item.id}`}>
      
      <ItemContainer>
      <List.Item>
        <List.Item.Meta avatar={<DiagramIcon src={diagramIcons[item.diagramType]} />}
          title={
            <Tooltip title={item.title}>
              <span>{item.title}</span>
            </Tooltip>
          }
          description={dayjs(item.updatedAt).fromNow()}
        />
      </List.Item>
      </ItemContainer>
    </Link>
  );
};
