/* Desktop Code Editor */
import React, { useRef, useEffect, useState, useCallback } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-language_tools";
import syntaxRules from "@diagram_codes/editor-syntax-rules";
import createCustomEditor from "./createCustomAceEditorMode";
import styled from "styled-components";
import { useAppContext } from "../../context/contextLib";

console.log('syntaxRules', syntaxRules)

/* Prevenir navegación hacia atrás con wheel */
const StyledEditor = styled(AceEditor)`
    overscroll-behavior-x: contain;
    .ace_content {
        overscroll-behavior-x: contain;
    }
`;

/* Codigo setup ace tomado de:
   https://github.com/securingsincity/react-ace/issues/126#issuecomment-345151567
*/
const Editor = ({
    value,
    errorLineIndex,
    diagramType,
    onChange,
    resizeSignal,
    contextSuggestions = [],
    style = {},
}) => {

    const { lineNumber } = useAppContext();
    
    const editorRef = useRef();
    /* Setup editor, usamos callback
    y no ref pues ref.current no se puede pasar
    como dependiencia
    ver: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node */
    const initEditor = useCallback((node) => {
        editorRef.current = node;
        if (errorLineIndex >= 0) {
            console.log("BUG!!!, falta mostrar markers aqui");
        }
        //Para facilitar demos agregamos funcion que simule typing
        window.startDemo = (val) => {
            setTimeout(() => {
                let offset = 0;
                let pause = false;
                let i = setInterval(() => {
                    if (pause) {
                        pause = false;
                        return;
                    }
                    offset++;
                    if (offset > val.length) {
                        clearInterval(i);
                        return;
                    }
                    if (val[offset] == "\n") {
                        pause = true;
                    }
                    let fragment = val.slice(0, offset);
                    onChange(fragment);
                }, 300);
            }, 3000);
        };

        if (!node) {
            return null;
        }
        node.editor
            .getSession()
            .setMode(createCustomEditor(syntaxRules[diagramType]));
        node.editor.setFontSize("14px");
        node.editor.setShowPrintMargin(false);
        node.editor.setTheme("ace/theme/textmate");
        //node.editor.getSession().setUseWrapMode(true);
        //Desabilitamos useWorker para que no se borren las annotations con el error
        node.editor.getSession().setOption("useWorker", false);
        /* Esto corrige un bug al hacer click sobre el editor con
               el split pane..
               TODO: cuando se cambie el tamano del panel hay que llamar resize de nuevo
            */
        setTimeout(() => {
            node.editor.resize();
        }, 300);

        //Configurar custom auto completer

        node.editor.setOptions({
            enableBasicAutocompletion: true,
        });
    }, []);

    



    useEffect(() => {
        const CustomAutocompleter = {
            getCompletions: function(editor, session, pos, prefix, callback) {
                console.log("completions pos:", pos);
                console.log("completions prefix:", pos);
                var wordList = contextSuggestions;
                console.log("contextSuggestions:", contextSuggestions);
                callback(
                    null,
                    wordList.map(function(word) {
                        return {
                            caption: word,
                            value: word,
                            //En meta se podría agregar texto adicional al lado del item autocomplete
                            meta: "",
                        };
                    })
                );
            },
        };
        if(editorRef.current){
            editorRef.current.editor.completers = [CustomAutocompleter];

        }
    }, [contextSuggestions]);

    //Asignar linea con error cuando cambie el valor de errorLineIndex
    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current.editor;
            if (typeof errorLineIndex == "undefined") {
                return;
            }
            if (errorLineIndex == -1) {
                editor.getSession().clearAnnotations();
            } else {
                //editor.getSession().clearAnnotations();

                editor.getSession().setAnnotations([
                    {
                        row: errorLineIndex,
                        column: 0,
                        //TODO: mejorar mensaje de error
                        text:
                            "Could not read this line, please check the code for syntax errors.", // Or the Json reply from the parser
                        type: "error", // also "warning" and "information"
                    },
                ]);
            }
        }
    }, [errorLineIndex]);

    //Llamar resize cuando se reciba señal de que cambio el layout padre
    //Ej Si el componente está en un Split pane se actualiza la variable
    //a un timestamp cada vez que termine una operacion de drag
    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current.editor;
            editor.resize();
        }
    }, [resizeSignal]);


    //GoToLine focus when a shape is clicked
    useEffect(() => {
        //go to line in editor
        if (editorRef.current == null){
            return;
        }
        console.log("go to line in editor:", lineNumber);
        const editor = editorRef.current.editor;
        console.log('element clicked - line focus executed');
        editor.gotoLine(lineNumber);
    },[lineNumber]); 

    const handleOnChange = (value) => {
        if (!editorRef.current) return;

        const cursorPos = editorRef.current.editor.getCursorPositionScreen();
        console.log("cursorPos:", cursorPos);
        //Necesitamos la posicion del cursor en el documento a partir del objeto {row,column}
        const cursorIndex = editorRef.current.editor.session
            .getDocument()
            .positionToIndex(cursorPos);
        console.log("cursorPosIndex:", cursorIndex);
        /* Modificacion, vamos a enviar BEFORE + codigoCursor + AFTER
           Como código de cursor usamos \u2020, la gramatica jison debe tenerlo en cuenta*/
        const beforeCursor = value.slice(0, cursorIndex + 1);
        const afterCursor = value.slice(cursorIndex + 1);

        const valueWithCursor = beforeCursor + "\u2020" + afterCursor;
        console.log("valueWithCursor", valueWithCursor);
        onChange(value, valueWithCursor);
    };

    return (
        <StyledEditor
            style={style}
            width="100%"
            height="100%"
            value={value}
            onChange={handleOnChange}
            ref={initEditor}
            mode="text"
            theme="textmate"
            setOptions={{
                useWorker: false,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true
            }}
        />
    );
};

export default Editor;
