export default {
    id:'space',
    name: 'Space',
    document: {
        backgroundColor: "#000",
        shadowColor: "none",
        shadow: false,
    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#fff',
        fillColor: "#111211",
        borderWidth: "2",
        borderColor: "#fff",
        borderRadius: 2,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'#fff',
        fillColor: "#000",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 0,
        padding: 4,
    },
    connectors: {
        color: "#fff",
        width: "2",
    }
};
