import React from "react";

const PathFromSVGStringWithLabels = ({ pathLayout }) => {
	const startArrowType = pathLayout.startArrowType ? pathLayout.startArrowType: "arrow";
	const endArrowType = pathLayout.endArrowType ? pathLayout.endArrowType: "arrow";
	const d = pathLayout.pathString;
	const start_label = pathLayout.start_label;
	const end_label = pathLayout.end_label;
	let markerEnd = pathLayout.hasEndArrow()
		? { markerEnd: `url(#`+endArrowType+`)` }
		: {};

	let markerStart = pathLayout.hasStartArrow()
		? { markerStart: `url(#`+startArrowType+`)` }
		: {};

	let scale = pathLayout.scale;
	let id = pathLayout.id ? {id:pathLayout.id} : {}
	let rotationAngle = pathLayout.getRotationAngle() || 0;
	let rotationPoint = pathLayout.getRotationPoint() || {x:0,y:0};
	let strTransform = `rotate(${rotationAngle},${rotationPoint.x}, ${rotationPoint.y}) scale(${scale})`
	let fill = pathLayout.fill
	const filterAttribute = pathLayout.shadow
    ? { filter: "url(#shadow-path)" }
    : {};


	return (
		<g>
			<path
				transform={strTransform}
				d={d}
	     		stroke={pathLayout.stroke}
				strokeDasharray={pathLayout.getStrokeDashArray()}
				strokeWidth={pathLayout.strokeWidth}
				fill={fill}
				{...markerEnd}
				{...id}
				{...filterAttribute}
				{...markerStart}
			/>
			<text x={start_label.x} y={start_label.y} font-size="smaller" fill={pathLayout.stroke} >{start_label.label}</text>
			<text x={end_label.x} y={end_label.y} font-size="smaller" fill={pathLayout.stroke}>{end_label.label}</text>
		</g>
		
	);
};

export default PathFromSVGStringWithLabels;
