

const query =  `
	query GetRecentDocs(
		$teamID: ID
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelRecentDocumentFilterInput
	    $limit: Int
	    $nextToken: String
	) {
	  recentDocumentsByTeam(filter:$filter, limit: $limit, teamID: $teamID, createdAt: $createdAt, sortDirection: $sortDirection, nextToken: $nextToken){
	    items {
	      document {
		      id
	          title
	          groupID
	          documentType
	          diagramType
	          diagramCode
	          theme
	          projectID
	          clientId
	          createdAt
	          updatedAt
	          deleted
	      }
	    }
	  }
	}
`

export default query;