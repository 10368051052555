export default {
    id:'coral',
    name: 'Coral-Dark',
    document: {
        backgroundColor: "#000",
        shadowColor: "none",
        shadow: false,
    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#FF7062',
        fillColor: "#111211",
        borderWidth: "1.8",
        borderColor: "#FF7062",
        borderRadius: 2,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'#FF7062',
        fillColor: "#000",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 0,
        padding: 4,
    },
    connectors: {
        color: "#FF7062",
        width: "1.8",
    }
};
