export default {
    id:'dark',
    name: 'Rock',
    document: {
        backgroundColor: "#464647",
        shadowColor: "black",
        shadow: false,
    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#464647',
        fillColor: "#CCCDCF",
        borderWidth: "2",
        borderColor: "white",
        borderRadius: 2,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'white',
        fillColor: "#464647",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 0,
        padding: 4,
    },
    connectors: {
        color: "#CCCDCF",
        width: "2",
    }
};
