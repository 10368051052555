import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Input, Empty, Layout } from "antd";
import { useDebounce, useDebounceCallback } from "@react-hook/debounce";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/contextLib";
import Editor from "../app-components/diagrams/editor";
import Preview from "../app-components/diagrams/preview";
import ListDocuments from "../app-components/documents/listDocuments";
import { useHistory, Link } from "react-router-dom";
import { PageHeader, Descriptions } from "antd";
import {FullSizeLayoutContainer} from '../layout'
import emptyBackground from "../assets/graphics/undraw_empty_street_sfxm.svg";

const PageLayout = styled(Layout)`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding: 4rem;
	background: #6c6e6f;
	overflow: auto;
`;

const PageContentsContainer = styled(Card)`
	max-width: 100%;
	min-width: 250px;
	background: rgb(255, 255, 255);
	border-radius: 10px;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(201, 201, 201, 1) 100%
	);
`;

export default (props) => {
	const { id } = useParams();
	const {
		recentFiles,
		currentTeam,
		projects,
		loadRecentDocuments,
	} = useAppContext();

	useEffect(() => {
		if (currentTeam) loadRecentDocuments();
	}, [currentTeam]);

	const history = useHistory();
	const noProjectsYet = projects.length === 0;
	const handleCreateProject = () => {
		history.push("/new-project");
	};

	const handleCreateDraft = () => {
		history.push("/new-draft");
	};

	return (
		<FullSizeLayoutContainer>
			
				<PageHeader
					ghost={false}
					title="Home"
					subTitle="Welcome"
					extra={[
						<Button type="primary" onClick={handleCreateDraft}>
							Quick Diagram Draft
						</Button>,
						!noProjectsYet && (
							<Button
								type="secondary"
								onClick={handleCreateProject}
							>
								Create a Project
							</Button>
						),
					]}/>
				
					{recentFiles.length > 0 && (
					  <Card title="Recent">
						<ListDocuments documents={recentFiles} />
					 </Card>
						
					)}

					{!noProjectsYet && recentFiles.length === 0 && (
						<Card>
							<Empty
								image={emptyBackground}
								imageStyle={{
									height: 200,
								}}
								description={
									<span>
										No recent{" "}
										<Link to="/projects">project</Link>{" "}
										documents.
									</span>
								}
							></Empty>
						</Card>
					)}

					{noProjectsYet && (
						<Empty
							image={emptyBackground}
							imageStyle={{
								height: 200,
							}}
							description={
								<span>
									No <Link to="/projects">Projects</Link> yet.
								</span>
							}
						>
							<Button
								type="primary"
								onClick={handleCreateProject}
							>
								Create A Project
							</Button>
						</Empty>
					)}
			
		</FullSizeLayoutContainer>
	);
};
