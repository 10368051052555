import React from 'react'
import styled from 'styled-components'
import { List, Avatar, Button, Skeleton, Card } from 'antd';
import {UserOutlined} from '@ant-design/icons'
import { useAppContext } from "../../context/contextLib";


export default () => {
	  const {
    	currentTeamMembers
  	} = useAppContext();

  	const getActiveActionButton = (item)=>{
  		if(item.isActive){
  			return <Button>Disable</Button>
  		}else {
  			return <Button>Enable</Button>
  		}
  	}

	return (

		<Card title="Team Members" bordered={false} style={{ width: 500 }}>
			<List
		    itemLayout="horizontal"
		    dataSource={currentTeamMembers}
		    renderItem={item => (
		      <List.Item actions={[]}>
		        <List.Item.Meta
		          avatar={<UserOutlined />}
		          title={<span>{item.email}</span>}
		         
		        />
		      </List.Item>
		    )}
		  />
	  </Card>
	)
}