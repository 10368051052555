import React, { useState } from "react";

import styled from 'styled-components'
import { Form, Input, Button, Checkbox, Typography, Card } from "antd";
import { Auth } from "aws-amplify";
import { useAppContext } from "../context/contextLib";
import { Link, useHistory } from "react-router-dom";

import {
  CenteredLayoutContainer,
  StyledLink,
  LoadingIndicator,
  ErrorMessage,
  Box,
} from "../layout";


const { Text } = Typography;

const ContainerCard = styled(Card)`
  width: 400px;
`

const layout = {
  style: {},
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default ({onEmailChanged}) => {
  
  //Referencia a form de confirmar cambio password (cuando necesita cambiarlo)
  const [emailForm] = Form.useForm();

  const onFinish = async (values) => {
    onEmailChanged(values.email);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
      <ContainerCard title="Please enter your email">
        <Form
          form={emailForm}
          {...layout}
          name="user-email"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email"
            name="email"
            type="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter your email",
              },
            ]}
          >
            <Input/>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Continue 
            </Button>
          </Form.Item>
        </Form>
      </ContainerCard>
  );
};
