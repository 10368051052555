import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
	List,
	Avatar,
	Button,
	Skeleton,
	Card,
	Input,
	PageHeader,
	Descriptions,
	Layout,
	Drawer,
	Popconfirm
} from "antd";
import { useDebounce, useDebounceCallback } from "@react-hook/debounce";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/contextLib";
import AceCodeEditor from "../app-components/diagrams/aceCodeEditor";
import Preview from "../app-components/diagrams/preview";
import { useHistory, Link } from "react-router-dom";
import Split from "react-split";
import { EditOutlined } from "@ant-design/icons";
import ShareSnapshotButton from "../app-components/diagrams/shareSnapshotButton";
import EditorContents from "../app-components/diagrams/editorContents";
import QuickReferenceDrawer from "../app-components/diagrams/quickReferenceDrawer";
import {LoadingContent} from '../layout';
import {GPTModal, OpenAIKeyPrompt} from './draftHome';



import { themes , getThemeById} from "../themes";

const DiagramEditorContainer = styled(Layout)`
	display: flex;
	height: 100%;
`;

const TitleInputChange = styled(Input)`
	width: 300px;
`;



export default (props) => {
	const { id } = useParams();
	const {
		loadDocument,
		currentDocument,
		currentTeam,
		setCurrentDocument,
		updateDocument,
		addRecentDocument,
		deleteDocument,
	} = useAppContext();
	const {} = useAppContext();
	const [editingName, setEditingName] = useState(false);
	const [errorLineIndex, setErrorLineIndex] = useState(-1);
	const [docsVisible, showDocs] = useState(false);

	const history = useHistory();
	const [lastDocumentIdLoaded, setLastDocumentIdLoaded] = useState();
	const [activeAIModal, setActiveAIModal] = useState(false);
	const [aiPrompt, setAIPrompt] = useState("");
	const refInputName = useRef();

	const debouncedUpdate = useDebounceCallback((doc) => {
		console.log("update doc");
		updateDocument(doc);
	}, 1000);

	//Cargar el diagrama
	useEffect(() => {
		//Aqui resetear estado
		(async () => {
			//Si el diagrama fue recien creado
			//entonces estará en currentDiagram y no
			//debemos cargarlo
			let doc = currentDocument;
			if (!currentDocument || currentDocument.id != id) {
				doc = await loadDocument(id);
			}
			//Registrar visita a documento
			//TODO: limitarlo para que sólo se haga si el mismo día no hay visitas al mismo doc
		})();
	}, [id]);

	//Registrar visita al documento
	useEffect(() => {
		if (
			currentDocument &&
			currentTeam &&
			currentDocument.id != lastDocumentIdLoaded
		) {
			setLastDocumentIdLoaded(currentDocument.id);
			addRecentDocument({
				teamID: currentTeam.id,
				documentID: currentDocument.id,
			});
		}
	}, [currentDocument, currentTeam]);

	//Mover focus a input cuando se cambie el nombre
	useEffect(() => {
		if (editingName) {
			setTimeout(() => {
				if (refInputName.current) {
					refInputName.current.focus();
				}
			}, 5);
		}
	}, [editingName]);

	const onCodeChangeHandler = (newValue) => {
		console.log("code changed:", newValue);
		const updatedDoc = { ...currentDocument, diagramCode: newValue };
		setCurrentDocument(updatedDoc);
		debouncedUpdate(updatedDoc);
	};

	const onThemeChangeHandler = (newValue) => {
		console.log("theme changed:", newValue);
		const updatedDoc = { ...currentDocument, theme: newValue };
		setCurrentDocument(updatedDoc);
		debouncedUpdate(updatedDoc);
	};

	const handleTitleChange = (ev) => {
		const updatedDoc = { ...currentDocument, title: ev.target.value };
		setCurrentDocument(updatedDoc);
	};

	const commitUpdateTitle = () => {
		updateDocument(currentDocument);
		setEditingName(false);
	};

	const handleClickChangeName = () => {
		setEditingName(true);
	};

	const goToProject = () => {
		history.push(`/project/${currentDocument.projectID}`);
	};

	if (!currentDocument) return <LoadingContent/>;


	const handleSyntaxError = (line) => {
		setErrorLineIndex(line);
	};

	const showRefDoc = () => {
		showDocs(true);
	};

	const onCloseDocs = () => {
		showDocs(false);
	};

	const handleDeleteDraft = () => {
		deleteDocument(currentDocument);
		goToProject();
	}

	const ProjectLink = () => {
		if (!currentDocument.project) return null;

		return (
			<Link to={`/project/${currentDocument.projectID}`}>
				{currentDocument.project.name}
			</Link>
		);
	};


	const title = editingName ? (
		<form onSubmit={commitUpdateTitle}>
			<TitleInputChange
				ref={refInputName}
				placeholder="Document name"
				value={currentDocument.title}
				onChange={handleTitleChange}
			/>
		</form>
	) : (
		<>{currentDocument.title}</>
	);



	return (
		<DiagramEditorContainer>
			<PageHeader
				onBack={goToProject}
				ghost={false}
				title={title}
				subTitle={
					editingName ? null : (
						<Button type="link" onClick={handleClickChangeName}>
							<EditOutlined />
							Rename
						</Button>
					)
				}
				extra={[
					<ShareSnapshotButton type="primary" />,
					<Button onClick={showRefDoc}>Quick reference</Button>,
					<Popconfirm placement="top" title={"Delete Document?"} onConfirm={handleDeleteDraft} okText="Yes" cancelText="No">
					     <Button> Delete </Button>
					</Popconfirm>
				]}
			>
			
			</PageHeader>
			<QuickReferenceDrawer diagramType={currentDocument.diagramType} onClose={onCloseDocs} visible={docsVisible} />
			<GPTModal
				diagramType={currentDocument.diagramType}
				aiPrompt={aiPrompt}
				setAIPrompt={setAIPrompt}
				visible={activeAIModal}
				onClose={() => setActiveAIModal(false)}
				onDiagramReady={(val) => {
					onCodeChangeHandler(val);
				}}
			/>
			<EditorContents
				diagramCode={currentDocument.diagramCode}
				diagramType={currentDocument.diagramType}
				loadedTheme={currentDocument.theme}
				onCodeChange={onCodeChangeHandler}
				onThemeChange={onThemeChangeHandler}
			/>
		</DiagramEditorContainer>
	);
};
