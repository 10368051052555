import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, Empty, Layout } from "antd";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/contextLib";
import ListDocuments from "../app-components/documents/listDocuments";
import { useHistory, Link } from "react-router-dom";
import { PageHeader, Descriptions, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";

import emptyBackground from "../assets/graphics/undraw_empty_street_sfxm.svg";
import {FullSizeLayoutContainer} from '../layout'
const TitleInputChange = styled(Input)`
	width: 300px;
`;

const PageContainer = styled(Layout)`
	background: #fff;
`;

export default (props) => {
	const { id } = useParams();
	const history = useHistory();
	const [editingName, setEditingName] = useState(false);

	const {
		currentProject,
		setCurrentProject,
		updateProject,
		currentProjectDocuments,
		loadCurrentProjectDocuments,
		loadProjectDetails,
		addDocument,
		setSelectedTheme
	} = useAppContext();

	setSelectedTheme(null);
	const refInputName = useRef();

	/*** TEST paginacion **/
	useEffect(()=>{
		window.createTestDiagrams = async ()=>{
			new Array(500).fill(0).forEach(async (i, ix)=>{
				console.log('creando doc:', ix)
				const newDoc = await addDocument({
					title: "test-"+ix,
					documentType: "diagram",
					diagramType: 'graph',
					diagramCode: 'a->b',
					projectID: currentProject.id,
					groupID: currentProject.groupID,
					theme: "default",
				});
			})
		}
	},[currentProject])

	/* Cargar detalles del proyecto si no lo tenemos en memoria */
	useEffect(() => {
		console.log("id param changed, loading project");
		//TODO: loading
		loadProjectDetails(id);
		loadCurrentProjectDocuments(id);
	}, [id]);

	//Mover focus a input cuando se cambie el nombre
	useEffect(() => {
		if (editingName) {
			setTimeout(() => {
				if (refInputName.current) {
					refInputName.current.focus();
				}
			}, 5);
		}
	}, [editingName]);

	console.log("currentProject", currentProject);

	const handleTitleChange = (ev) => {
		const updatedProject = { ...currentProject, name: ev.target.value };
		setCurrentProject(updatedProject);
	};

	const commitUpdateTitle = () => {
		updateProject(currentProject);
		setEditingName(false);
	};

	const handleClickChangeName = () => {
		setEditingName(true);
	};

	const handleNewDiagramClick = () => {
		history.push("/new");
	};

	if (!currentProject) {
		return null;
	}

	const title = editingName ? (
		<form onSubmit={commitUpdateTitle}>
			<TitleInputChange
				ref={refInputName}
				placeholder="Project name"
				value={currentProject.name}
				onChange={handleTitleChange}
			/>
		</form>
	) : (
		currentProject.name
	);

	console.log("currentProjectDocuments", currentProjectDocuments);

	return (
		<FullSizeLayoutContainer>
			<PageHeader
				ghost={false}
				title={title}
				subTitle={
					editingName ? null : (
						<Button type="link" onClick={handleClickChangeName}>
							<EditOutlined />
							Rename
						</Button>
					)
				}
				extra={[
					currentProjectDocuments.length > 0 && (
						<Button type="primary" onClick={handleNewDiagramClick}>
							New Diagram
						</Button>
					),
				]}
			></PageHeader>
			{currentProjectDocuments.length > 0 && (
				<Card title="Documents">
					<ListDocuments documents={currentProjectDocuments} />
				</Card>
			)}

			{currentProjectDocuments.length === 0 && (
				<Card>
					<Empty
						image={emptyBackground}
						imageStyle={{
							height: 200,
						}}
						description={<span>Project created.</span>}
					>
						<Button type="primary" onClick={handleNewDiagramClick}>
							Add a new Diagram
						</Button>
					</Empty>
				</Card>
			)}
		</FullSizeLayoutContainer>
	);
};
