import React, { useState, useEffect } from "react";

import { Form, Input, Button, Checkbox, Typography, message } from "antd";
import { Auth } from "aws-amplify";
import { useAppContext } from "../context/contextLib";
import { useHistory, Link } from "react-router-dom";

import {
	CenteredLayoutContainer,
	StyledLink,
	LoadingIndicator,
	Box,
} from "../layout";

const { Text } = Typography;

const layout = {
	style: {
		width: "400px",
	},
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16,
	},
};

export default () => {
	const {
		isUserAuthenticated,
		setIsUserAuthenticated,
		temporalUserEmail,
		setTemporalUserEmail,
	} = useAppContext();


	const [password, setPassword] = useState("");
	const [code, setCode] = useState("");
	const [success, setSuccess] = useState(false);

	const history = useHistory()

	useEffect(()=>{
		if(success){
			history.push('/login')
			message.info("Password updated")
		}

	}, [success])

	const onFinish = async () => {
		try {
			await Auth.forgotPasswordSubmit(temporalUserEmail, code, password);
			setSuccess(true);
		} catch (error) {
			//TODO: cambiar
			alert(error.message);
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log(errorInfo);
	};

	const [forgotPasswordForm] = Form.useForm();


	return (
		<CenteredLayoutContainer>
			<Box>
				<p>
					{" "}
					A confirmation code was sent to your email, plese enter it
					below to reset your password.
				</p>
				<Form
					{...layout}
					name="basic"
					initialValues={{ remember: true }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
		

					<Form.Item
						label="Code"
						name="code"
						rules={[
							{
								required: true,
								message:
									"Please enter the code sent to your email",
							},
						]}
					>
						<Input
							value={code}
							onChange={(ev) => {
								setCode(ev.target.value);
							}}
						/>
					</Form.Item>

					<Form.Item
						label="New Password"
						name="password"
						rules={[
							{
								required: true,
								message: "Please enter the new password",
							},
						]}
					>
						<Input.Password
							value={password}
							onChange={(ev) => {
								setPassword(ev.target.value);
							}}
						/>
					</Form.Item>

					<Form.Item {...tailLayout}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Box>
		</CenteredLayoutContainer>
	);
};
