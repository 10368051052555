export default {
    id:'pastel2',
    name:'Pastel 2',
    document: {
        backgroundColor: "#ffffff",
        shadowColor: "black",
        shadow: false,

    },
    /* Estilos que aplican a nodos */
    nodes: {
        fontFamily: "Arial",
        fontSize: "16",
        textColor:'#be79df',
        fillColor: "#fbcffc",
        borderWidth: "2",
        borderColor: "#be79df",
        borderRadius: 4,
        padding: 8,
    },
    /* Estilos que aplican a texto suelto */
    labels: {
        fontFamily: "Arial",
        fontSize: "14",
        textColor:'#be79df',
        fillColor: "#fff",
        borderWidth: "0",
        borderColor: "none",
        borderRadius: 5,
        padding: 2,
    },
    connectors: {
        color: "#be79df",
        width: "2",
    }
};
