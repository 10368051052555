import React from "react";
import { text } from "@hugo_volare/shared-utilities";
const { getLines } = text;

/* new: soporte multiline, crea tspans y usa offset
  relativo en y */

const Text = ({
	position,
	fontSize,
	fontWeight,
	fontFamily,
	textColor,
	children
}) => {
	const trstr = `translate(${position.x}, ${position.y})`;
	//Para conservar espacios en blanco y tabs, reemplazarlos por caracter unicode de espacio
	const lines = getLines(children).map(l => l.replace(/[ \t]/g, "\u00A0"));
	return (
		<text
			fill={textColor}
			fontSize={fontSize}
			fontWeight={fontWeight}
			transform={trstr}
			alignmentBaseline="middle"
			fontFamily={fontFamily}
		>
			{lines.map((l, ix) => (
				<tspan
					key={ix}
					x="0"
					dy={fontSize}
					fontFamily={fontFamily}
					fontSize={fontSize}
					fontWeight={fontWeight}
				>
					{l}
				</tspan>
			))}
		</text>
	);
};

export default Text;
