import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Card,
  Layout,
  PageHeader,
  message,
  Spin,
} from "antd";
import { Auth } from "aws-amplify";
import { useAppContext } from "../context/contextLib";
import { Link, useHistory } from "react-router-dom";
import {
  CenteredLayoutContainer,
  StyledLink,
  LoadingIndicator,
  ErrorMessage,
  Box,
} from "../layout";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_test_51HEhQCBhW031b1kgAMjIFRBN5cTyxIATw0I7eXnXO0OGHdq5TfVgUF7IBvYtzWSTStl8Jup0znNcqiHPh9eZO8Pq00zBrFHGyb"
);

const { Text } = Typography;

const Container = styled(Layout)`
  height: 100%;
`;
const layout = {
  style: {},
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default () => {
  const {
    createStripeCheckoutSession,
    isUserAuthenticated,
    setIsUserAuthenticated,
    temporalUserEmail,
    setTemporalUserEmail,
    temporalUserPassword,
    setTemporalUserPassword,
    logout,
  } = useAppContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let history = useHistory();

  useEffect(() => {
    if (isUserAuthenticated) {
      logout();
    }
  }, [isUserAuthenticated]);

  //Stripe
  //TODO: tomar el priceId de parametro ruta
  const priceId = "price_1HEhTVBhW031b1kgD4UNgo2D";

  //Referencia a form de confirmar cambio password (cuando necesita cambiarlo)
  const [signupForm] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setHasError(false);
      setLoading(true);
      await Auth.signUp({
        username: email,
        password: password,
      });

      //Save email (needed for confirmation)
      setTemporalUserEmail(email);
      setTemporalUserPassword(password);

      history.push("/signup/confirm");
    } catch (e) {
      setErrorMessage(e.message || "Authentication failed");
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Container>
      <PageHeader
        ghost={false}
        title={"New Account"}
        subTitle="Please register to continue."
      ></PageHeader>

      <CenteredLayoutContainer>
        <Card>
          <Spin spinning={loading} delay={500}>
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Email"
                name="email"
                type="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter your email",
                  },
                ]}
              >
                <Input
                  value={email}
                  onChange={(ev) => {
                    setEmail(ev.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter your password." },
                ]}
              >
                <Input.Password
                  value={password}
                  onChange={(ev) => {
                    setPassword(ev.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Confirm"
                name="confirm-password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  value={confirmPassword}
                  onChange={(ev) => {
                    setConfirmPassword(ev.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Submit <LoadingIndicator visible={loading} />
                </Button>

                <div>
                  {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </div>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </CenteredLayoutContainer>
    </Container>
  );
};
