/* Grupo con el editor de texto y el preview en un split panel
   Es usado por DraftHome y Diagram Home */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  List,
  Avatar,
  Button,
  Skeleton,
  Card,
  Input,
  PageHeader,
  Descriptions,
  Layout,
  Drawer,
} from "antd";
import Split from "react-split";
import AceCodeEditor from "./aceCodeEditor";
import CodeEditor from "./editor";
import Preview from "./preview";
import diagramRegistry from "@hugo_volare/diagrams-layout-lib";
import { useDebounce, useDebounceCallback } from "@react-hook/debounce";
import Parsers from "@hugo_volare/diagrams-parser";

import { useAppContext } from "../../context/contextLib";

import markdownRenderer from "./markdownRenderer.js";
import latexRenderer from "./latexRenderer.js";

import ThemeListSelector from "./themeListSelector";

import { themes, getThemeById } from "../../themes";

const EditorContents = styled(Card)`
  height: 100%;
  display: flex;
  overflow: hidden;
  & > .ant-card-body {
    width: 100%;
    padding: 0;
  }
`;

const HorizontalSplitContainer = styled.div`
  height: 100%;

  .splitPanel {
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 400px;
    height: 100%;
    align-items: stretch;
  }

  .gutter {
    cursor: s-resize;
    background: red;
    height: auto;
  }

  .gutter-horizontal {
    content: " ";
    cursor: e-resize;
    background: rgb(240, 242, 245);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 10px;
  }
`;

/* Nota: height:auto en codemirror
   sirve para que siempre muestre todo el documento
   y permita busquedas 

(Aunque no se usa pues cambiamos a Ace editor)
  */

const ContainerEditor = styled.div`
  overflow: auto;
  .CodeMirror {
    height: auto;
  }
`;

const ContainerPreview = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
`;

const ContainerTheme = styled.div`
  overflow: hidden;
  height: auto;
  cursor: pointer;
`;

const ContainerRight = styled.div`
  overflow: hidden;
  height: auto;
`;

const ThemeSelectorArea = styled.div`
  grid-area: ThemeSelector;
  overflow: auto;
`;

var markdownCache = {};
var katexCache = {};

export default ({
  diagramCode,
  diagramType,
  loadedTheme,
  onCodeChange,
  onThemeChange,
  showTextEditor = true,
}) => {
  const _loadedTheme = getThemeById(loadedTheme);
  let {
    selectedTheme,
    setLineNumber,
    setSelectedTheme,
    setKatexMapNotEmpty,
    setMarkdownMapNotEmpty,
  } = useAppContext();

  const [errorLineIndex, setErrorLineIndex] = useState(-1);
  //TODO: error line index
  //TODO: Click to line

  let [lastGoodLayout, setLastGoodLayout] = useState(null);
  let [resizeSignal, setResizeSignal] = useState(null);

  /* En este campo guardamos el codigo del diagrama incluyendo
       un caracter especial para el cursor.
       Esto con el fin de poder tener autocomplete inteligente
       y que el parser llene las sugerencias con base en las posicion
       del cursor.
       (El cursor se representa con el caracter '\u2020')
    */
  let [valueWithCursor, setValueWithCursor] = useState(null);
  let [contextSuggestions, setContextSuggestions] = useState([]);

  let module = diagramRegistry.getModule(diagramType);
  console.log("diagramType", diagramType);
  console.log("module", module);
  let model = new module.Model();
  let parser = Parsers[diagramType].parser;
  let parserAutocomplete = Parsers[diagramType].parserAutocomplete;

  //TODO: El parser del autocomplete se debe hacer por cada cambio, pero el de generacion de diagrama se puede debouncear

  /* Debounced:
       Parsea el input y genera el diagram layout
    */

  //debounce para que no se renderice tan frecuente
  const setLastGoodLayoutDebounced = useDebounceCallback((layout) => {
    setLastGoodLayout(layout);
  }, 600);

  const setCompileDiagramDebounced = useDebounceCallback((diagramCode) => {
    compileDiagram(diagramCode);
  }, 300);

  const compileDiagram = (input) => {
    try {
      let theme_to_apply = null;
      if (selectedTheme != null) {
        theme_to_apply = getThemeById(selectedTheme);
      } else {
        theme_to_apply = getThemeById(loadedTheme);
      }
      setContextSuggestions([]);
      parser.parse(input, model);
      let markdownMap = null;
      let latexMap = null;
      if (model.markdownExpressions && model.markdownExpressions.length > 0) {
        markdownMap = markdownRenderer(
          model.markdownExpressions,
          theme_to_apply,
          markdownCache
        );
        setMarkdownMapNotEmpty(true);
      } else {
        setMarkdownMapNotEmpty(false);
      }
      if (model.latexExpressions && model.latexExpressions.length > 0) {
        latexMap = latexRenderer(
          model.latexExpressions,
          theme_to_apply,
          katexCache
        );
        setKatexMapNotEmpty(true);
      } else {
        setKatexMapNotEmpty(false);
      }
      let layout = module.getLayout(
        model,
        theme_to_apply,
        latexMap,
        markdownMap
      );
      setLastGoodLayoutDebounced(layout);

      //Obtener autocomplete suggestions
      //Le pasamos el model que debe tener info del parseo anterior
      try {
        if (parserAutocomplete) {
          parserAutocomplete.parse(valueWithCursor, model);
          console.log("model suggestions:", model.suggestions);
          setContextSuggestions(model.suggestions);
        }
      } catch (e) {
        //Ignore autocomplete parsing errors
        console.log("Could not parse (autocomplete)");
      } finally {
        parserAutocomplete && parserAutocomplete.cleanupAfterParse();
      }
    } catch (e) {
      console.error(e);
    } finally {
      parser.cleanupAfterParse();
    }
  };

  //obtener autocomplete suggestions cada vez que cambia el valueWithCursor
  useEffect(() => {}, [valueWithCursor]);

  useEffect(() => {
    setCompileDiagramDebounced(diagramCode);
  }, [diagramCode, valueWithCursor]);

  useEffect(() => {
    compileDiagram(diagramCode);
  }, [selectedTheme]);

  const handleNodeClick = (d) => {
    console.log("node clicked!", d);
    setLineNumber(d);
  };

  const handleZoomRequest = (val) => {
    console.log("zoom request", val);
  };

  const onSelectTheme = (val) => {
    setSelectedTheme(val.id);
    onThemeChange(val.id);
  };

  /* Señal para que el editor se refresque 
       y así se resuelve inconsistencias cuando
       cambia el tamaño */
  const setLastSplit = (val) => {
    setResizeSignal(val);
  };

  if (!showTextEditor) {
    return (
      <ContainerPreview>
        <Preview
          diagramLayout={lastGoodLayout}
          onRequestZoomChange={handleZoomRequest}
          theme={_loadedTheme}
          onClickGroupElement={() => {}}
        />
      </ContainerPreview>
    );
  }

  return (
    <EditorContents>
      <HorizontalSplitContainer>
        <Split
          sizes={showTextEditor ? [0, 100] : [40, 60]}
          minSize={100}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className="splitPanel"
          onDragEnd={() => {
            setLastSplit(new Date());
          }}
        >
          <ContainerEditor>
            <AceCodeEditor
              value={diagramCode}
              errorLineIndex={errorLineIndex}
              diagramType={diagramType}
              onChange={(newValue, valueWithCursor) => {
                setValueWithCursor(valueWithCursor);
                onCodeChange(newValue);
              }}
              resizeSignal={resizeSignal}
              contextSuggestions={contextSuggestions}
            />
          </ContainerEditor>

          <ContainerRight>
            <ContainerTheme>
              <ThemeSelectorArea>
                <x-box>
                  <ThemeListSelector
                    selectedTheme={_loadedTheme}
                    handleItemClick={onSelectTheme}
                    style={{ width: "100%", height: "100%", overflow: "auto" }}
                  />
                </x-box>
              </ThemeSelectorArea>
            </ContainerTheme>
            <ContainerPreview>
              <Preview
                diagramLayout={lastGoodLayout}
                onRequestZoomChange={handleZoomRequest}
                theme={_loadedTheme}
                onClickGroupElement={handleNodeClick}
              />
            </ContainerPreview>
          </ContainerRight>
        </Split>
      </HorizontalSplitContainer>
    </EditorContents>
  );
};
