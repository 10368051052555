import defaultTheme from "./default";
import pastel1 from "./pastel1";
import pastel2 from "./pastel2";
import cornflower from "./cornflower";
import imperial from "./imperial";
import dark from "./dark";
import orange from "./orange";
import blue from "./blue";
import blueback from "./blueback";
import green from "./green";
import greenback from "./greenback";
import space from "./space";
import coraldark from "./coral-dark";
import grapefruit from "./v2/grapefruit";
import perrywinkle from "./v2/perrywinkle";
import pink from "./v2/pink";

const themes = [
  defaultTheme,
  imperial,
  blue,
  blueback,
  green,
  greenback,
  orange,
  pastel1,
  pastel2,
  cornflower,
  dark,
  space,
  coraldark,
  grapefruit,
  perrywinkle,
  pink,
];

/* Retorna el theme por id, si no existe ninguno con ese id
   retorna el primer tema que debe ser el default */
function getThemeById(id){
    const theme = themes.find((item)=> item.id === id);
    return theme;
}

export { themes, getThemeById };
