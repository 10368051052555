import React from 'react';
import { List, Avatar, Button, Skeleton, Card, Input, Empty } from "antd";
import CenteredContainer from '../layout/centeredLayoutContainer'
import { useHistory, Link } from "react-router-dom";
import { useAppContext } from "../context/contextLib";


export default()=>{

	const {
		logout
	} = useAppContext();


	const handleLogout = async ()=>{
		logout();
	}

	return <CenteredContainer>
		<Card title="Inactive Account">
		
		 	Your Account is currenty disabled.
		 	<p>
		 		Please send us an email to <b>support@diagram.codes</b> if you have any questions or want to re-enable it.
		 	</p>

		 	<Button onClick={handleLogout} type="link">Sign In with other Account </Button>

		</Card>
	</CenteredContainer>
}