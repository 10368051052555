import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Avatar, Button, Skeleton, Card, message } from "antd";
import { PageHeader, Descriptions, Input } from "antd";
import TeamList from "../app-components/teams/teamList";
import TeamDetails from "../app-components/teams/teamDetails";
import AddTeamMember from "../app-components/teams/addTeamMember";
import TeamMemberList from "../app-components/teams/teamMemberList";
import { useAppContext } from "../context/contextLib";

const PageContentContainer = styled.div`
    background: #fff;
`


export default () => {
    const { currentTeam, isAdmin, createStripeBillingPortalSession } = useAppContext();
    if(!currentTeam) 
        return null;

    const openBillingPortal = async ()=>{

       const portalUrl = await createStripeBillingPortalSession();
       if(portalUrl){
         window.location = portalUrl;
       }
    }

    return (
        <PageContentContainer>
            <PageHeader
                ghost={false}
                title={"Account Settings"}
                subTitle={currentTeam.name}
                extra={[
                ]}
            ></PageHeader>
            
            <Card title="Billing">
                <Button onClick={openBillingPortal} type="primary">Billing Settings</Button>
            </Card>
            
        </PageContentContainer>
    );
};
